import { IDriveParameter } from '.';

export enum DriveStatus {
  INHIBIT = 0,
  READY = 1,
  STOP = 2,
  SCAN = 3,
  RUN = 4,
  SUPPLY_LOSS = 5,
  DECELERATION = 6,
  DC_INJECTION = 7,
  POSITION = 8,
  TRIP = 9,
  ACTIVE = 10,
  OFF = 11,
  HAND = 12,
  AUTO = 13,
  HEAT = 14,
  UNDER_VOLTAGE = 15,
  PHASING = 16,
}

export const DriveStatusNames = Object.freeze({
  [DriveStatus.INHIBIT]: 'Inhibit',
  [DriveStatus.READY]: 'Ready',
  [DriveStatus.STOP]: 'Stop',
  [DriveStatus.SCAN]: 'Scan',
  [DriveStatus.RUN]: 'Run',
  [DriveStatus.SUPPLY_LOSS]: 'Supply Loss',
  [DriveStatus.DECELERATION]: 'Deceleration',
  [DriveStatus.DC_INJECTION]: 'DC Injection',
  [DriveStatus.POSITION]: 'Position',
  [DriveStatus.TRIP]: 'Trip',
  [DriveStatus.ACTIVE]: 'Active',
  [DriveStatus.OFF]: 'Off',
  [DriveStatus.HAND]: 'Hand',
  [DriveStatus.AUTO]: 'Auto',
  [DriveStatus.HEAT]: 'Heat',
  [DriveStatus.UNDER_VOLTAGE]: 'Under Voltage',
  [DriveStatus.PHASING]: 'Phasing',
});

export class DriveStatusParameter implements IDriveParameter {
  public name: string;
  public value: DriveStatus;

  constructor(name: string, value: DriveStatus) {
    this.name = name;
    this.value = value;
  }

  public get valueString() {
    return DriveStatusNames[this.value];
  }

  public toJSON() {
    return {
      ...this,
      valueString: this.valueString,
    };
  }

  public toString() {
    return this.valueString;
  }
}
