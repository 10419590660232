import React from 'react';
import { withRouter, BrowserRouterProps } from 'react-router-dom';
import { Button, ButtonProps } from 'reactstrap';

import { withFirebase } from '../config';
import { ROUTES } from '../config';
import { FirebaseProps } from '../config/context';

const SignOutButton: React.ComponentType<
  ButtonProps & FirebaseProps & BrowserRouterProps
> = withRouter(
  withFirebase((props: ButtonProps & FirebaseProps & BrowserRouterProps) => {
    const { firebase, history, staticContext, ...rest } = props;

    const signOut = (e: React.MouseEvent<any, MouseEvent>) => {
      e.preventDefault();
      firebase?.signOut();
      props.history.push(ROUTES.LOGIN);
    };

    return (
      <SignOutButtonRender
        signOut={signOut}
        buttonProps={rest as ButtonProps}
      />
    );
  })
);

interface SignOutButtonRenderProps {
  buttonProps: ButtonProps;
  signOut: (event: React.MouseEvent<any, MouseEvent>) => void;
}

const SignOutButtonRender = (props: SignOutButtonRenderProps) => {
  return (
    <Button type='button' onClick={props.signOut} {...props.buttonProps}>
      Sign&nbsp;Out
    </Button>
  );
};

export { SignOutButton };
