import React from 'react';
import { NavLink as LinkNav } from 'react-router-dom';
import { ROUTES } from '../config';
import { Nav, NavItem, NavLink, Container, Row, Col } from 'reactstrap';

const NavFooter = () => (
  <div className='w-100 bg-dark d-print-none'>
    <Container className='py-4'>
      <Nav className='w-100'>
        <NavItem>
          <NavLink
            tag={LinkNav}
            to={ROUTES.LANDING}
            exact
            className='text-white'
          >
            Home
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={LinkNav} to={ROUTES.ABOUT} className='text-white'>
            About
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag={LinkNav}
            to={ROUTES.AUTOMATEDSEALING}
            className='text-white'
          >
            Automated&nbsp;Sealing
          </NavLink>
        </NavItem>
        <NavItem className='ml-sm-auto'>
          <NavLink
            href={'mailto:info@cracksealtech.com'}
            className='text-white'
          >
            Contact Us - info@cracksealtech.com
          </NavLink>
        </NavItem>
      </Nav>
      <Row>
        <Col>
          <div className='pl-2 mt-4'>
            <span className='text-muted'>
              © {new Date().getFullYear()} Crack Seal Technologies, All Rights
              Reserved
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export { NavFooter };
