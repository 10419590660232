import _ from 'lodash';
import { IDriveParameter } from '.';

export enum AMCStatus {
  EGB_LOCKED = 1 << 0,
  CAM_COMPLETE = 1 << 1,
  MOVEMENT_COMPLETE = 1 << 2,
  PROFILE_COMPLETE = 1 << 3,
  FOLLOWING_ERROR = 1 << 4,
  AT_SPEED = 1 << 5,
  MASTER_SPEED_TOO_HIGH = 1 << 6,
  FOLLOWING_ERROR_STOP = 1 << 7,
  HOME_COMPLETE = 1 << 8,
  HOME_OFFSET_COMPLETE = 1 << 9,
  POSITIVE_HARDWARE_LIMIT = 1 << 10,
  NEGATIVE_HARDWARE_LIMIT = 1 << 11,
  POSITIVE_SOFTWARE_LIMIT = 1 << 12,
  NEGATIVE_SOFTWARE_LIMIT = 1 << 13,
  HOME_REQUIRED = 1 << 14,
  POSITION_FEEDBACK_INITIALIZED = 1 << 15,
}

export const AMCStatusNames = Object.freeze({
  [AMCStatus.EGB_LOCKED]: 'EGB Locked',
  [AMCStatus.CAM_COMPLETE]: 'Cam Complete',
  [AMCStatus.MOVEMENT_COMPLETE]: 'Movement Complete',
  [AMCStatus.PROFILE_COMPLETE]: 'Profile Complete',
  [AMCStatus.FOLLOWING_ERROR]: 'Following Error',
  [AMCStatus.AT_SPEED]: 'At Speed',
  [AMCStatus.MASTER_SPEED_TOO_HIGH]: 'Master Speed Too High',
  [AMCStatus.FOLLOWING_ERROR_STOP]: 'Following Error Stop',
  [AMCStatus.HOME_COMPLETE]: 'Home Complete',
  [AMCStatus.HOME_OFFSET_COMPLETE]: 'Home Offset Complete',
  [AMCStatus.POSITIVE_HARDWARE_LIMIT]: 'Positive Hardware Limit',
  [AMCStatus.NEGATIVE_HARDWARE_LIMIT]: 'Negative Hardware Limit',
  [AMCStatus.POSITIVE_SOFTWARE_LIMIT]: 'Positive Software Limit',
  [AMCStatus.NEGATIVE_SOFTWARE_LIMIT]: 'Negative Software Limit',
  [AMCStatus.HOME_REQUIRED]: 'Home Required',
  [AMCStatus.POSITION_FEEDBACK_INITIALIZED]: 'Position Feedback Initialized',
});

export class AMCStatusParameter implements IDriveParameter {
  public name: string;
  public value: AMCStatus;

  constructor(
    name: string = 'AMC Status',
    value: AMCStatus = AMCStatus.EGB_LOCKED
  ) {
    this.name = name;
    this.value = value;
  }

  public get valueString() {
    let outputStringArray: string[] = [];
    _.forEach(AMCStatus, (value, key) => {
      if ((this.value & Number(value)) > 0)
        outputStringArray.push(AMCStatusNames[value]);
    });
    return outputStringArray.join(', ');
  }

  public toJSON() {
    return {
      ...this,
      valueString: this.valueString,
    };
  }

  public toString() {
    return this.valueString;
  }
}
