import _ from 'lodash';
import { IDriveParameter } from '.';

export enum StatusWord {
  DRIVE_HEALTHY = 1 << 0,
  DRIVE_ACTIVE = 1 << 1,
  ZERO_SPEED = 1 << 2,
  RUNNING_LEQ_MIN_SPEED = 1 << 3,
  BELOW_SET_SPEED = 1 << 4,
  AT_SPEED = 1 << 5,
  ABOVE_SET_SPEED = 1 << 6,
  RATED_LOAD_REACHED = 1 << 7,
  CURRENT_LIMIT_ACTIVE = 1 << 8,
  REGENERATING = 1 << 9,
  BRAKING_IGBT_ACTIVE = 1 << 10,
  BRAKING_RESISTOR_ALARM = 1 << 11,
  REVERSE_DIRECTION_COMMANDED = 1 << 12,
  REVERSE_DIRECTION_RUNNING = 1 << 13,
  SUPPLY_LOSS = 1 << 14,
}

export const StatusWordNames = Object.freeze({
  [StatusWord.DRIVE_HEALTHY]: 'Drive Healthy',
  [StatusWord.DRIVE_ACTIVE]: 'Drive Active',
  [StatusWord.ZERO_SPEED]: 'Zero Speed',
  [StatusWord.RUNNING_LEQ_MIN_SPEED]: 'Running At Or Below Minimum Speed',
  [StatusWord.BELOW_SET_SPEED]: 'Below Set Speed',
  [StatusWord.AT_SPEED]: 'At Speed',
  [StatusWord.ABOVE_SET_SPEED]: 'Above Set Speed',
  [StatusWord.RATED_LOAD_REACHED]: 'Rated Load Reached',
  [StatusWord.CURRENT_LIMIT_ACTIVE]: 'Current Limit Active',
  [StatusWord.REGENERATING]: 'Regenerating',
  [StatusWord.BRAKING_IGBT_ACTIVE]: 'Braking IGBT Active',
  [StatusWord.BRAKING_RESISTOR_ALARM]: 'Braking Resistor Alarm',
  [StatusWord.REVERSE_DIRECTION_COMMANDED]: 'Reverse Direction Commanded',
  [StatusWord.REVERSE_DIRECTION_RUNNING]: 'Reverse Direction Running',
  [StatusWord.SUPPLY_LOSS]: 'Supply Loss',
});

export class StatusWordParameter implements IDriveParameter {
  public name: string;
  public value: StatusWord;

  constructor(
    name: string = 'Status Word',
    value: StatusWord = StatusWord.DRIVE_HEALTHY
  ) {
    this.name = name;
    this.value = value;
  }

  public get valueString() {
    let outputStringArray: string[] = [];
    _.forEach(StatusWord, (value, key) => {
      if ((this.value & Number(value)) > 0)
        outputStringArray.push(StatusWordNames[value]);
    });
    return outputStringArray.join(', ');
  }

  public toJSON() {
    return {
      ...this,
      valueString: this.valueString,
    };
  }

  public toString() {
    return this.valueString;
  }
}
