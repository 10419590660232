import React, { useState } from 'react';
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const Landing = () => {
  const [reportModalOpen, updateReportModalOpen] = useState(false);
  const [image1ModalOpen, updateImage1ModalOpen] = useState(false);
  const [image2ModalOpen, updateImage2ModalOpen] = useState(false);

  return (
    <Container fluid className='p-0 m-0'>
      <Row
        className=''
        style={{
          backgroundImage:
            "url('https://storage3.cracksealtech.com/road-crack-large.jpg?alt=media&token=daecb066-a649-437d-98b1-271fb77a630c')",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          opacity: 0.9,
          height: '75vh',
        }}
      >
        <Col className='text-center align-self-center'>
          <h1
            style={{ textShadow: '0px 0px 10px black' }}
            className='text-white'
          >
            <img
              src='logo-white.png'
              className='fluid'
              style={{
                maxWidth: 650,
                width: '100%',
              }}
              alt=''
            />
          </h1>
        </Col>
      </Row>
      <Row className='bg-warning'>
        <Container className='my-4'>
          <Row className='text-center'>
            <Col xs={12} sm={6}>
              <img
                src='https://storage3.cracksealtech.com/imaging-truck-back-small.jpg'
                alt='surveying system on truck'
                className='img-fluid rounded'
              />
              {/* <caption className='w-100 d-block py-1 text-center'>
                image shows our lab unit
              </caption> */}
            </Col>
            <Col xs={12} sm={6} className='d-flex flex-column my-3 my-md-0'>
              <Row className='flex-grow-1'>
                <Col className='my-auto offset-md-1' md={10}>
                  <h1 className='h2 mb-2 text-center'>
                    Precision Quoting System
                  </h1>
                  {/* <h4>Fast | Precise | Simple</h4> */}
                  {/* <div className=''>
                    Determines detailed cracking information on road segments,
                    allowing a data-driven estimating and bidding process.{' '}d
                    <br />
                    Realizes millimeter-level road feature analysis to measure
                    total length, width, and density of cracking.
                  </div> */}
                  <div className='my-3 h5 font-weight-normal'>
                    Quickly determine the extent of cracking on road segments,
                    allowing for a data-driven estimating and bidding process.{' '}
                  </div>
                  <div className='my-3 h5 font-weight-normal'>
                    Using millimeter-level road feature analysis, measure total
                    length, width, and density of cracking, all in a simple,
                    easy-to-use and quick-to-operate system.
                  </div>
                </Col>
              </Row>
              {/* <Row className='d-none d-md-block'>
                <Col>
                  <img
                    src='https://storage3.cracksealtech.com/imaging-truck-side-wide-small.jpg'
                    alt='surveying system on truck'
                    className='img-fluid rounded'
                  />
                </Col>
              </Row> */}
            </Col>
          </Row>
          {/* <Row>
            <caption className='w-100 d-block text-center'>
              Images show our lab unit in a truck. Production unit will have
              some additional equipment and hardware can be adjusted based on vehicle
              type
            </caption>
          </Row> */}
        </Container>
      </Row>
      <Row>
        <Container className='my-4'>
          <Row className='text-left'>
            <Col className='d-flex flex-column align-items-left' lg={9}>
              <h2 className='mb-4'>Benefits</h2>
              <ul className='flex-grow-1 d-flex flex-column justify-content-around'>
                <li className='h5 text-secondary font-weight-normal'>
                  Generate accurate job bids
                </li>
                <li className='h5 text-secondary font-weight-normal'>
                  Identify the total length and width of cracking before bidding
                </li>
                <li className='h5 text-secondary font-weight-normal'>
                  Filter by cracks that meet specified size criteria
                </li>
                <li className='h5 text-secondary font-weight-normal'>
                  Understand the cracking density over the length of the roadway
                </li>
                <li className='h5 text-secondary font-weight-normal'>
                  Visualize the projected volume of sealant required for each
                  job
                </li>
              </ul>
            </Col>
            <Col xs={8} lg={3} className='text-center offset-2 offset-lg-0'>
              <div className='mx-auto'>
                <img
                  src='https://storage3.cracksealtech.com/EStateRd5-small.jpg'
                  alt='report thumbnail'
                  className='img-fluid img-thumbnail'
                  style={{ cursor: 'pointer' }}
                  onClick={() => updateReportModalOpen(true)}
                />
                <a
                  href='https://storage3.cracksealtech.com/EStateRd5.pdf'
                  className='btn btn-outline-secondary btn sm w-100'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  View as PDF
                </a>
              </div>

              <Modal
                centered
                fullscreen
                size='xl'
                toggle={() => updateReportModalOpen(!reportModalOpen)}
                isOpen={reportModalOpen}
              >
                <ModalHeader
                  toggle={() => updateReportModalOpen(!reportModalOpen)}
                >
                  Sample Report
                </ModalHeader>
                <ModalBody>
                  <img
                    src='https://storage3.cracksealtech.com/EStateRd5-large.jpg'
                    alt='report'
                    className='img-fluid'
                  />
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className='bg-warning'>
        <Container className='my-4'>
          <h2 className='mb-4 text-center'>How it Works</h2>
          <Row>
            <Col xs={12} md={6}>
              <img
                src='https://storage3.cracksealtech.com/00000394-large.jpg'
                className='img-fluid mt-2 mt-md-0 rounded-top'
                alt='example road'
                onClick={() => updateImage1ModalOpen(true)}
                style={{ cursor: 'pointer' }}
              />
              <img
                src='https://storage3.cracksealtech.com/00000394-segmentation-large.png'
                className='img-fluid mb-2 mb-md-0 rounded-bottom'
                alt='example crack and offroad detection'
                onClick={() => updateImage1ModalOpen(true)}
                style={{ cursor: 'pointer' }}
              />
              <Modal
                centered
                fullscreen
                size='xl'
                toggle={() => updateImage1ModalOpen(!image1ModalOpen)}
                isOpen={image1ModalOpen}
                style={{ maxWidth: '100%' }}
              >
                <ModalHeader
                  toggle={() => updateImage1ModalOpen(!image1ModalOpen)}
                >
                  Example Image 1
                </ModalHeader>
                <ModalBody>
                  <img
                    src='https://storage3.cracksealtech.com/00000394-large.jpg'
                    className='img-fluid'
                    alt='example road'
                  />
                  <img
                    src='https://storage3.cracksealtech.com/00000394-segmentation-large.png'
                    className='img-fluid'
                    alt='example crack and offroad detection'
                  />
                </ModalBody>
              </Modal>
            </Col>
            <Col xs={12} md={6}>
              <img
                src='https://storage3.cracksealtech.com/00000847-large.jpg'
                className='img-fluid mt-2 mt-md-0 rounded-top'
                alt='example road'
                onClick={() => updateImage2ModalOpen(true)}
                style={{ cursor: 'pointer' }}
              />
              <img
                src='https://storage3.cracksealtech.com/00000847-segmentation-large.png'
                className='img-fluid mb-2 mb-md-0 rounded-bottom'
                alt='example crack and offroad detection'
                onClick={() => updateImage2ModalOpen(true)}
                style={{ cursor: 'pointer' }}
              />

              <Modal
                centered
                fullscreen
                size='xl'
                toggle={() => updateImage2ModalOpen(!image2ModalOpen)}
                isOpen={image2ModalOpen}
                style={{ maxWidth: '100%' }}
              >
                <ModalHeader
                  toggle={() => updateImage2ModalOpen(!image2ModalOpen)}
                >
                  Example Image 1
                </ModalHeader>
                <ModalBody>
                  <img
                    src='https://storage3.cracksealtech.com/00000847-large.jpg'
                    className='img-fluid'
                    alt='example road'
                  />
                  <img
                    src='https://storage3.cracksealtech.com/00000847-segmentation-large.png'
                    className='img-fluid'
                    alt='example crack and offroad detection'
                  />
                </ModalBody>
              </Modal>
            </Col>
          </Row>
          <Row className='text-center my-3'>
            {/* <Col><h5 className='font-weight-normal'>
              Place the hardware system in the bed of any pick-up truck. Drive
              roadways at up to 45 mph, or full highway speed (70+ mph) with the
              high-speed option. Start and stop imaging from our mobile app.
            </h5>
            <h5 className='font-weight-normal'>
              After imaging, take storage device and upload files. We will take
              it from there. Leave equipment in truck for ease of use or remove
              when finished, and we will send you a road report as soon as image
              analysis is complete. The standard report lead time is less than
              one week.
            </h5></Col> */}
            <Col md={6}>
              <ul className='text-left h5 font-weight-normal'>
                <li className='my-2'>
                  Place the hardware system in the bed of any pick-up truck.
                </li>
                <li className='my-2'>
                  Drive roadways at up to 45 mph, or full highway speed (70+
                  mph) with the high-speed option.
                </li>
                <li className='my-2'>
                  Enter road info, and start and stop imaging from our mobile
                  app.
                </li>
              </ul>
            </Col>
            <Col>
              <ul className='text-left h5 font-weight-normal'>
                <li className='my-2'>
                  After imaging, take storage device and upload files. We will
                  take it from there.{' '}
                </li>
                <li className='my-2'>
                  Leave equipment in truck for ease of use or remove when
                  finished, and we will send you a road report as soon as image
                  analysis is complete.The standard report lead time is less
                  than one week.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className=''>
        <Container className='my-4'>
          <Row className='text-center'>
            <Col className='d-flex flex-column justify-content-center'>
              <h2 className='mb-4 text-center'>
                How it Works | Within Our Service Area
              </h2>
              <h5 className='text-secondary font-weight-normal'>
                If the job is located within our service area, you can submit a
                road report request through our online portal. You will receive
                a quote and report delivery date shortly after. We will visit
                the project location, survey the road segment, analyze the
                imagery, and generate a cracking report. Please contact us for
                more information.
              </h5>
            </Col>
            <Col xl={4}>
              <img
                src='https://storage3.cracksealtech.com/service-map-small-straight.jpg'
                alt='service area counties'
                className='img-fluid'
                style={{ maxHeight: 500 }}
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export { Landing };
