import React, { PropsWithChildren } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Login, withAuthentication, PrivateRoute } from './authentication';
import { Contact } from './contact';
import { Drive } from './drive-data';
import { DriveList } from './drive-data';
import { Landing } from './landing';
import { About } from './about';
import { Navigation, NavFooter } from './layout';
import { ROUTES } from './config';
import { AutomatedSealing } from './automated-sealing';
import { Report } from './report';

const App = withAuthentication((props: PropsWithChildren<object>) => {
  return (
    <Router>
      <Navigation />
      <Container
        fluid
        className='d-flex flex-column'
        style={{ minHeight: '100vh' }}
      >
        <Switch>
          <PrivateRoute exact path={ROUTES.LANDING} component={Landing} />
          <PrivateRoute path={ROUTES.ABOUT} component={About} />
          <PrivateRoute path={ROUTES.CONTACT} component={Contact} />
          <PrivateRoute
            path={ROUTES.AUTOMATEDSEALING}
            component={AutomatedSealing}
          />
          <PrivateRoute path={ROUTES.REPORT} component={Report} />
          <PrivateRoute path={ROUTES.REPORTADMIN} component={Report} />
          <PrivateRoute path={ROUTES.DRIVE1} component={Drive} />
          <PrivateRoute path={ROUTES.DRIVE2} component={Drive} />
          <PrivateRoute path={ROUTES.DRIVESTATUS} component={DriveList} />
          <Route path={ROUTES.LOGIN} component={Login} />
        </Switch>
      </Container>
      <NavFooter />
    </Router>
  );
});

export default App;
