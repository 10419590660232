import {
  NumberParameter,
  OnOffParameter,
  Constants,
  AdditionalStatusBit,
  AdditionalStatusBitParameter,
  AMCStatusParameter,
  AMCStatus,
  StatusWord,
  StatusWordParameter,
  DriveStatus,
  DriveStatusParameter,
  AMCReferenceSelect,
  AMCReferenceSelectParameter,
} from './parameters';

export class DriveData {
  public currentMagnitude: NumberParameter = new NumberParameter(
    'Current Magnitude',
    0,
    Constants.CURRENT_MAGNITUDE_UNITS,
    Constants.CURRENT_MAGNITUDE_PRECISION,
    Constants.VM_DRIVE_CURRENT_UNIPOLAR_MIN,
    Constants.VM_DRIVE_CURRENT_UNIPOLAR_MAX
  );
  public iq: NumberParameter = new NumberParameter(
    'IQ',
    0,
    Constants.IQ_UNITS,
    Constants.IQ_UNITS_PRECISION,
    Constants.VM_DRIVE_CURRENT_MIN,
    Constants.VM_DRIVE_CURRENT_MAX
  );
  public hardwareEnable: OnOffParameter = new OnOffParameter(
    'Hardware Enable',
    false
  );
  public amcStatus: AMCStatusParameter = new AMCStatusParameter(
    'AMC Status Parameter',
    AMCStatus.EGB_LOCKED
  );
  public p2SpeedFeedback: NumberParameter = new NumberParameter(
    'P2 Speed Feedback',
    0,
    Constants.P2_SPEED_FEEDBACK_UNITS,
    Constants.P2_SPEED_FEEDBACK_PRECISION,
    Constants.VM_SPEED_MIN,
    Constants.VM_SPEED_MAX
  );
  public p2RevolutionPolePitchCounter: NumberParameter = new NumberParameter(
    'P2 Revolution/Pole Pitch Counter',
    0,
    undefined,
    Constants.P2_REVOLUTION_PRECISION,
    Constants.P2_REVOLUTION_MIN,
    Constants.P2_REVOLUTION_MAX
  );
  public p2Position: NumberParameter = new NumberParameter(
    'P2 Position',
    0,
    undefined,
    Constants.P2_POSITION_PRECISION,
    Constants.P2_POSITION_MIN,
    Constants.P2_POSITION_MAX
  );
  public motorProtectionAccumulator: NumberParameter = new NumberParameter(
    'Motor Protection Accumulator',
    0,
    Constants.MOTOR_PROTECTION_ACCUMULATOR_UNITS,
    Constants.AMC_POSITION_REFERENCE_PRECISION,
    Constants.MOTOR_PROTECTION_ACCUMULATOR_MIN,
    Constants.MOTOR_PROTECTION_ACCUMULATOR_MAX
  );
  public statusWord: StatusWordParameter = new StatusWordParameter(
    'Status Word',
    StatusWord.DRIVE_HEALTHY
  );
  public additionalStatusBit: AdditionalStatusBitParameter = new AdditionalStatusBitParameter(
    'Additional Status Bits',
    AdditionalStatusBit.NONE
  );
  public driveStatus: DriveStatusParameter = new DriveStatusParameter(
    'Drive Status',
    DriveStatus.INHIBIT
  );
  public amcSlavePosition: NumberParameter = new NumberParameter(
    'AMC Slave Position',
    0,
    Constants.AMC_SLAVE_POSITION_UNITS,
    Constants.AMC_SLAVE_POSITION_PRECISION,
    Constants.VM_AMC_POSITION_MIN,
    Constants.VM_AMC_POSITION_MAX
  );
  public amcPositionReference: NumberParameter = new NumberParameter(
    'AMC Position Reference',
    0,
    Constants.AMC_POSITION_REFERENCE_UNITS,
    Constants.AMC_POSITION_REFERENCE_PRECISION,
    Constants.VM_AMC_POSITION_REF_MIN,
    Constants.VM_AMC_POSITION_REF_MAX
  );
  public amcReferenceSelect: AMCReferenceSelectParameter = new AMCReferenceSelectParameter(
    'AMC Reference Select',
    AMCReferenceSelect.STOP
  );
  public amcProfileAcceleration: NumberParameter = new NumberParameter(
    'AMC Profile Acceleration',
    0,
    Constants.AMC_PROFILE_ACCELERATION_UNITS,
    Constants.AMC_PROFILE_ACCELERATION_PRECISION,
    Constants.VM_AMC_RATE_UNIPOLAR_MIN,
    Constants.VM_AMC_RATE_UNIPOLAR_MAX
  );
  public amcProfileDeceleration: NumberParameter = new NumberParameter(
    'AMC Profile Deceleration',
    0,
    Constants.AMC_PROFILE_DECELERATION_UNITS,
    Constants.AMC_PROFILE_DECELERATION_PRECISION,
    Constants.VM_AMC_RATE_UNIPOLAR_MIN,
    Constants.VM_AMC_RATE_UNIPOLAR_MAX
  );
  public amcProfileMaxSpeed: NumberParameter = new NumberParameter(
    'AMC Profile Maximum Speed',
    0,
    Constants.AMC_PROFILE_MAXIMUM_SPEED_UNITS,
    Constants.AMC_PROFILE_MAXIMUM_SPEED_PRECISION,
    Constants.VM_AMC_SPEED_UNIPOLAR_MIN,
    Constants.VM_AMC_SPEED_UNIPOLAR_MAX
  );
  public run: OnOffParameter = new OnOffParameter('Run', false);

  public fromNumbers(inputArray: number[]) {
    let intermediateArray: number[] = [];
    for (let i = 0; i < inputArray.length / 2; i++) {
      intermediateArray.push((inputArray[2 * i] << 16) | inputArray[2 * i + 1]);
    }

    this.currentMagnitude.value = intermediateArray[0];
    this.iq.value = intermediateArray[1];
    this.hardwareEnable.value = intermediateArray[2] > 0;
    this.amcStatus.value = intermediateArray[3];
    this.p2SpeedFeedback.value = intermediateArray[4];
    this.p2RevolutionPolePitchCounter.value = intermediateArray[5];
    this.p2Position.value = intermediateArray[6];
    this.motorProtectionAccumulator.value = intermediateArray[7];
    this.statusWord.value = intermediateArray[8];
    this.additionalStatusBit.value = intermediateArray[9];
    this.driveStatus.value = intermediateArray[10];
    this.amcSlavePosition.value = intermediateArray[11];
    this.amcPositionReference.value = intermediateArray[12];
    this.amcReferenceSelect.value = intermediateArray[13];
    this.amcProfileAcceleration.value = intermediateArray[14];
    this.amcProfileDeceleration.value = intermediateArray[15];
    this.amcProfileMaxSpeed.value = intermediateArray[16];
    this.run.value = intermediateArray[17] > 0;
    return this;
  }
}
