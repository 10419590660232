import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const About = () => (
  <Container className='pt-4'>
    <h1 className='text-center'>
      About Us
      {/* <br />
      <small className='text-muted'>Founders</small> */}
    </h1>

    <Row className='my-4'>
      <Col xs={12} md='auto' className='align-self-center text-center'>
        <img
          src='https://storage3.cracksealtech.com/austin-vanputten-small.jpg'
          className='img-fluid img-thumbnail'
          style={{ maxHeight: '300px' }}
          alt=''
        ></img>
      </Col>
      <Col className='align-self-center'>
        <h2 className='text-center text-md-left'>
          Austin VanPutten <br />
          <small className='text-muted'>Mechanical Engineer</small>
        </h2>
        <span>
          Austin leads the hardware design and production for the crack survey
          system. His background is in industrial automation and engineering and
          holds a bachelor’s degree in mechanical engineering from Grove City
          College. He likes elegant solutions, and the way technology and
          automation can unlock new potential. Austin enjoys board games, hiking
          and kayaking after work.
        </span>
      </Col>
    </Row>
    <hr />
    <Row className='my-4'>
      <Col xs={12} md='auto' className='align-self-center text-center'>
        <img
          src='https://storage3.cracksealtech.com/jarod-hanko-small.jpg'
          className='img-fluid img-thumbnail'
          style={{ maxHeight: '300px' }}
          alt=''
        ></img>
      </Col>
      <Col className='align-self-center'>
        <h2 className='text-center text-md-left'>
          Jarod Hanko <br />
          <small className='text-muted'>Software Developer</small>
        </h2>
        <span>
          Jarod graduated from Grand Valley State University with a Bachelor's
          degree in Computer Science. His background is in full-stack mobile and
          web applications design and development. He enjoys solving new
          programming challenges, and is especially interested in machine
          learning. Outside of work, he enjoys hiking, backpacking,
          rollerblading, and 3d printing.
        </span>
      </Col>
    </Row>
  </Container>
);

export { About };
