import React, { useState } from 'react';
import { NavLink as LinkNav } from 'react-router-dom';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';
import { AuthUserContext, SignOutButton } from '../authentication';
import { ROUTES, withFirebase } from '../config';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {({ authUser }) =>
      authUser != null ? <PrivateNavigation /> : <PublicNavigation />
    }
  </AuthUserContext.Consumer>
);

const PublicNavigation = withFirebase(() => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color='dark' dark expand='md'>
      <Container>
        <NavbarBrand href='/'>
          <img
            src='logo-white.svg'
            className='w-100'
            style={{ maxHeight: '60px' }}
            alt=''
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav justified className='ml-auto align-items-center' navbar>
            <NavItem>
              <NavLink tag={LinkNav} to={ROUTES.LANDING} exact>
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={LinkNav} to={ROUTES.AUTOMATEDSEALING}>
                Research
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={LinkNav} to={ROUTES.ABOUT}>
                About
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink tag={LinkNav} to={ROUTES.CONTACT}>
                Contact
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink tag={LinkNav} to={ROUTES.LOGIN}>
                Login
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
});

const PrivateNavigation = withFirebase(() => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color='dark' dark expand='md'>
      <NavbarBrand href='/'>
        <img
          src='logo-white.svg'
          className='w-100'
          style={{ maxHeight: '60px' }}
          alt=''
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav justified className='ml-auto' navbar>
          <NavItem>
            <NavLink tag={LinkNav} to={ROUTES.LANDING} exact>
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={LinkNav} to={ROUTES.AUTOMATEDSEALING}>
              Research
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink tag={LinkNav} to={ROUTES.DRIVESTATUS}>
              Drive&nbsp;Status
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink tag={LinkNav} to={ROUTES.ABOUT}>
              About
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink tag={LinkNav} to={ROUTES.CONTACT}>
              Contact
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink tag={LinkNav} to={ROUTES.REPORT}>
              Report
            </NavLink>
          </NavItem>
          <NavItem>
            <SignOutButton tag={NavLink} color='link' className='rounded-0' />
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
});

export { Navigation };
