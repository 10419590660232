import React, { useState, useEffect } from 'react';
import { FirebaseProps, withFirebase } from '../config/context';

const AuthUserContext = React.createContext<{
  authUser: firebase.User | null;
  setAuthUser: React.Dispatch<
    React.SetStateAction<firebase.User | null>
  > | null;
}>({ authUser: null, setAuthUser: null });

const withAuthentication = <P extends object>(
  Component: React.ComponentType<P>
) =>
  withFirebase((props: P & FirebaseProps) => {
    const initialFirebaseUserJSON = localStorage.getItem('firebase-user');
    const initialFirebaseUser =
      initialFirebaseUserJSON != null
        ? (JSON.parse(initialFirebaseUserJSON) as firebase.User | null)
        : null;

    const [authUser, setAuthUser] = useState<firebase.User | null>(
      initialFirebaseUser
    );

    useEffect(() => {
      let listener = props.firebase?.auth.onAuthStateChanged((authUser) => {
        setAuthUser(authUser);
        localStorage.setItem('firebase-user', JSON.stringify(authUser));
      });

      return () => listener?.call(null);
    }, [props.firebase]);

    return (
      <AuthUserContext.Provider value={{ authUser, setAuthUser }}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  });

export { AuthUserContext, withAuthentication };
