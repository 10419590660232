import { IDriveParameter } from '.';

export class OnOffParameter implements IDriveParameter {
  public name: string;
  public value: boolean;
  public units?: string;

  constructor(name: string, value: boolean, units?: string) {
    this.name = name;
    this.value = value;
    this.units = units;
  }

  public get valueString() {
    return this.value ? 'On' : 'Off';
  }

  public toJSON() {
    return {
      ...this,
      valueString: this.valueString,
    };
  }

  public toString() {
    return this.valueString;
  }
}
