import React from 'react';
import { Firebase } from '.';

const FirebaseContext = React.createContext<Firebase | null>(null);

export interface FirebaseProps {
  firebase?: Firebase | null;
}

const withFirebase = <P extends object>(Component: React.ComponentType<P>) => (
  props: P & FirebaseProps
) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export { FirebaseContext, withFirebase };
