import app, { firestore } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

/**
 * Reference: https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial
 */
class Firebase {
  driveApp: app.app.App;
  auth: app.auth.Auth;
  db: firestore.Firestore;

  constructor() {
    this.driveApp = app.initializeApp(config);

    this.auth = app.auth();
    this.db = firestore(this.driveApp);
  }

  //no plans to have register process atm, but for future use
  // createUserWithEmailAndPassword = (email: string, password: string) =>
  //   this.auth.createUserWithEmailAndPassword(email, password);

  signInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  //not used atm
  // passwordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  //check to make sure that this.auth.currentUser != null
  // passwordUpdate = (password: string) =>
  //   this.auth.currentUser?.updatePassword(password);

  //need to switch to session based in the future
  collectionQuery = (collectionName: string) =>
    this.db.collection(collectionName).limit(1);

  collectionMostRecentQuery = (collectionName: string) =>
    this.db.collection(collectionName).orderBy('id', 'desc').limit(1);
}

export { Firebase };
