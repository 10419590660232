import _ from 'lodash';
import { IDriveParameter } from '.';

export class NumberParameter implements IDriveParameter {
  public name: string;
  public value: number;
  public units?: string;

  protected _lowValue?: number;
  protected _highValue?: number;
  protected _precision?: number;

  constructor(
    name: string,
    value: number,
    units?: string,
    precision?: number,
    lowValue?: number,
    highValue?: number
  ) {
    let actualValue: number =
      !_.isUndefined(lowValue) && !_.isUndefined(highValue)
        ? _.clamp(value, lowValue, highValue)
        : value;

    this.name = name;
    this.value = actualValue;
    this.units = units;
    this._lowValue = lowValue;
    this._highValue = highValue;
    this._precision = precision;
  }

  public get valueString() {
    return `${this.value.toFixed(this._precision)} ${this.units || ''}`;
  }

  public toString(): string {
    return this.valueString;
  }

  public toJSON() {
    return {
      ...this,
      valueString: this.valueString,
    };
  }
}
