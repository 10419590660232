//Current Magnitude
export const VM_DRIVE_CURRENT_UNIPOLAR_MIN = 0.0;
export const VM_DRIVE_CURRENT_UNIPOLAR_MAX = 99999.999;
export const CURRENT_MAGNITUDE_PRECISION = 3;
export const CURRENT_MAGNITUDE_UNITS = 'A';
//Iq
export const VM_DRIVE_CURRENT_MIN = -9999.999;
export const VM_DRIVE_CURRENT_MAX = 9999.999;
export const IQ_UNITS_PRECISION = 3;
export const IQ_UNITS = 'A';
//P2 Speed Feedback
export const VM_SPEED_MIN = -50000.0; //actually calculated by drive
export const VM_SPEED_MAX = 50000.0; //actually calculated by drive
export const P2_SPEED_FEEDBACK_UNITS = 'rpm';
export const P2_SPEED_FEEDBACK_PRECISION = 1;
//P2 Revolution/Pole Pitch Counter
export const P2_REVOLUTION_MIN = 0;
export const P2_REVOLUTION_MAX = 65535;
export const P2_REVOLUTION_PRECISION = 0;
//P2 Position
export const P2_POSITION_MIN = 0;
export const P2_POSITION_MAX = 65535;
export const P2_POSITION_PRECISION = 0;
//Motor Protection Accumulator
export const MOTOR_PROTECTION_ACCUMULATOR_MIN = 0.0;
export const MOTOR_PROTECTION_ACCUMULATOR_MAX = 200.0;
export const MOTOR_PROTECTION_ACCUMULATOR_PRECISION = 1;
export const MOTOR_PROTECTION_ACCUMULATOR_UNITS = '%';
//AMC Slave Position
export const VM_AMC_POSITION_MIN = -2147483648;
export const VM_AMC_POSITION_MAX = -2147483647;
export const AMC_SLAVE_POSITION_PRECISION = 0;
export const AMC_SLAVE_POSITION_UNITS = 'UU';
//AMC Position Reference
export const VM_AMC_POSITION_REF_MIN = -2147483648;
export const VM_AMC_POSITION_REF_MAX = 2147483647;
export const AMC_POSITION_REFERENCE_PRECISION = 0;
export const AMC_POSITION_REFERENCE_UNITS = 'UU';
//AMC Profile Acceleration
export const VM_AMC_RATE_UNIPOLAR_MIN = 0.0;
export const VM_AMC_RATE_UNIPOLAR_MAX = 1073741.823;
export const AMC_PROFILE_ACCELERATION_PRECISION = 3;
export const AMC_PROFILE_ACCELERATION_UNITS = 'UU/ms²';
//AMC Profile Deceleration
export const AMC_PROFILE_DECELERATION_PRECISION = 3;
export const AMC_PROFILE_DECELERATION_UNITS = 'UU/ms²';
//AMC Profile Maximum Speed
export const VM_AMC_SPEED_UNIPOLAR_MIN = 0.0;
export const VM_AMC_SPEED_UNIPOLAR_MAX = 21474836.47;
export const AMC_PROFILE_MAXIMUM_SPEED_PRECISION = 2;
export const AMC_PROFILE_MAXIMUM_SPEED_UNITS = 'UU/ms²';

export const Constants = {
  VM_DRIVE_CURRENT_UNIPOLAR_MIN,
  VM_DRIVE_CURRENT_UNIPOLAR_MAX,
  CURRENT_MAGNITUDE_PRECISION,
  CURRENT_MAGNITUDE_UNITS,
  VM_DRIVE_CURRENT_MIN,
  VM_DRIVE_CURRENT_MAX,
  IQ_UNITS_PRECISION,
  IQ_UNITS,
  VM_SPEED_MIN,
  VM_SPEED_MAX,
  P2_SPEED_FEEDBACK_UNITS,
  P2_SPEED_FEEDBACK_PRECISION,
  P2_REVOLUTION_MIN,
  P2_REVOLUTION_MAX,
  P2_REVOLUTION_PRECISION,
  P2_POSITION_MIN,
  P2_POSITION_MAX,
  P2_POSITION_PRECISION,
  MOTOR_PROTECTION_ACCUMULATOR_MIN,
  MOTOR_PROTECTION_ACCUMULATOR_MAX,
  MOTOR_PROTECTION_ACCUMULATOR_PRECISION,
  MOTOR_PROTECTION_ACCUMULATOR_UNITS,
  VM_AMC_POSITION_MIN,
  VM_AMC_POSITION_MAX,
  AMC_SLAVE_POSITION_PRECISION,
  AMC_SLAVE_POSITION_UNITS,
  VM_AMC_POSITION_REF_MIN,
  VM_AMC_POSITION_REF_MAX,
  AMC_POSITION_REFERENCE_PRECISION,
  AMC_POSITION_REFERENCE_UNITS,
  VM_AMC_RATE_UNIPOLAR_MIN,
  VM_AMC_RATE_UNIPOLAR_MAX,
  AMC_PROFILE_ACCELERATION_PRECISION,
  AMC_PROFILE_ACCELERATION_UNITS,
  AMC_PROFILE_DECELERATION_PRECISION,
  AMC_PROFILE_DECELERATION_UNITS,
  VM_AMC_SPEED_UNIPOLAR_MIN,
  VM_AMC_SPEED_UNIPOLAR_MAX,
  AMC_PROFILE_MAXIMUM_SPEED_PRECISION,
  AMC_PROFILE_MAXIMUM_SPEED_UNITS,
};
