import React, { useState, ChangeEvent, MouseEvent } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withFirebase, FirebaseProps } from '../config';
import { ROUTES } from '../config';
import { AuthUserContext } from '.';

const Login = withRouter(
  withFirebase((props: RouteComponentProps & FirebaseProps) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<Error | null>(null);

    const updateEmail = (e: ChangeEvent<HTMLInputElement>) => {
      e.currentTarget && setEmail(e.currentTarget.value);
    };

    const updatePassword = (e: ChangeEvent<HTMLInputElement>) => {
      e.currentTarget && setPassword(e.currentTarget.value);
    };

    const authContext = React.useContext(AuthUserContext);
    const onSubmit = (e: MouseEvent) => {
      //authenticate
      e.preventDefault();

      props.firebase
        ?.signInWithEmailAndPassword(email, password)
        .then((auth) => {
          if (authContext.setAuthUser != null)
            authContext.setAuthUser(auth.user);
          setEmail('');
          setPassword('');
          props.history.push(ROUTES.REPORT);
        })
        .catch((error: Error) => {
          setError(error);
        });
    };

    return (
      <LoginRender
        email={email}
        password={password}
        error={error}
        updateUsername={updateEmail}
        updatePassword={updatePassword}
        onSubmit={onSubmit}
      />
    );
  })
);

interface LoginRenderProps {
  email: string;
  password: string;
  error: Error | null;
  updateUsername: (e: ChangeEvent<HTMLInputElement>) => void;
  updatePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: MouseEvent) => void;
}

const LoginRender = (props: LoginRenderProps) => {
  const { email, password, error, updateUsername, updatePassword, onSubmit } =
    props;

  return (
    <Row className='my-auto'>
      <Col xs={12} sm={{ size: 8, offset: 2 }} md={{ size: 4, offset: 4 }}>
        <Card>
          <CardBody>
            <CardTitle className='text-center h3'>Login</CardTitle>
            <Form>
              <FormFeedback valid={error != null}>
                {error && error.message}
              </FormFeedback>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type='email'
                  name='username'
                  id='username'
                  value={email}
                  onChange={updateUsername}
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  type='password'
                  name='password'
                  id='password'
                  value={password}
                  onChange={updatePassword}
                />
              </FormGroup>
              <Button type='submit' color='primary' block onClick={onSubmit}>
                Submit
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export { Login };
