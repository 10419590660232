import React, { useState } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardText,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { MiniHeader } from '../layout';

interface ToggleImageProps {
  url1: string;
  url2: string;
  toggleText1?: string;
}

const ToggleImage = (
  props: ToggleImageProps & React.PropsWithChildren<object>
) => {
  const { url1, url2, toggleText1 } = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <div onClick={toggleOpen}>
      {open ? (
        <div style={{ cursor: 'pointer' }}>
          <img
            src={url2}
            className='img-fluid'
            loading='lazy'
            alt=''
            style={{
              background: 'url("/icons/loading.svg")',
              minHeight: 300,
              minWidth: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>
      ) : (
        <div style={{ cursor: 'pointer' }}>
          <div className='position-absolute h-100 w-100 d-flex justify-content-center align-items-center'>
            <div>
              <img
                src='/icons/iconmonstr-media-control-48.svg'
                className='w-25'
                loading='lazy'
                alt=''
              />
              <br />
              <span className='display-4'>
                {toggleText1 || 'Click to Play'}
              </span>
            </div>
          </div>
          <img src={url1} className='img-fluid' loading='lazy' alt='' />
        </div>
      )}
    </div>
  );
};

const AutomatedSealing = (
  props: AutomatedSealingProps & React.PropsWithChildren<object>
) => (
  <React.Fragment>
    <Container fluid className='p-0 m-0'>
      {props.noHeader || <MiniHeader />}
      <Row className=''>
        <Container className='text-center my-4'>
          <Row>
            <Col>
              <h2 className='mb-4'>Automated Crack Sealing</h2>
            </Col>
          </Row>
          <Row noGutters>
            <Col lg={{ size: 8 }} xs={12}>
              <div className='embed-responsive embed-responsive-16by9'>
                <iframe
                  width='1366'
                  height='768'
                  src='https://www.youtube-nocookie.com/embed/eVuNvRSyQqc'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  className='mx-auto embed-responsive-item'
                  title='Automated Crack Sealing Demo'
                ></iframe>
              </div>
            </Col>
            <Col
              lg={4}
              xs={12}
              className='text-left bg-light p-3 text-dark d-flex'
            >
              <div className='p-3 align-self-center'>
                <h2 className='h4 text-center'>Proof of Concept</h2>
                Testing of proof of concept system to demonstrate initial
                capabilities, including machine learning, mapping, path finding,
                and sealant logic. The system operates while in motion. System
                is operated by a single driver that is not on a public roadway.
                This system uses spray chalk not crack sealant. This proof of
                concept demonstrates the primary system challenges working. Some
                clips are played at higher speeds to be more representative of
                the final machine.
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className='bg-warning'>
        <Container className='my-4'>
          <h1 className='h2 mb-4 text-center'>How it Works</h1>
          <Row className='text-center'>
            <ListGroup flush>
              <ListGroupItem className='bg-transparent'>
                <Row>
                  <Col xs={6} md={4}>
                    <figure>
                      <img
                        src='https://storage3.cracksealtech.com/008125.jpg?alt=media&token=7c1e1167-b705-4596-9589-0fa48eaefde4'
                        className='figure-img img-fluid'
                        loading='lazy'
                        alt=''
                      />
                      <figcaption className='figure-caption'>
                        Original Image
                      </figcaption>
                    </figure>
                  </Col>
                  <Col xs={6} md={4}>
                    <figure>
                      <img
                        src='https://storage3.cracksealtech.com/008125_composite.png?alt=media&token=a3572aa5-0d3f-475c-847d-48df4868910c'
                        className='figure-img img-fluid'
                        loading='lazy'
                        alt=''
                      />
                      <figcaption className='figure-caption'>
                        Detected Cracks (edges and center overlay)
                      </figcaption>
                    </figure>
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <figure>
                      <img
                        src='https://storage3.cracksealtech.com/008125_skeleton.png?alt=media&token=71945178-ead6-45ce-bdff-7a84f46b515b'
                        className='figure-img img-fluid'
                        loading='lazy'
                        alt=''
                      />
                      <figcaption className='figure-caption'>
                        Detected Crack (centerline)
                      </figcaption>
                    </figure>
                  </Col>
                  <Col sm={6} md={12} className='align-self-center'>
                    <h3>Machine Learning Assisted Crack Detection</h3>
                    <div>
                      Software algorithms combined with machine learning is used
                      to quickly find cracks in images. The operator has the
                      option to make adjustments, which can be collected to
                      further train the automated system
                    </div>
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className='bg-transparent'>
                <Row className='d-flex'>
                  <Col xs={12} md={8} className='align-self-center'>
                    <ToggleImage
                      url1='https://storage3.cracksealtech.com/pathfindinggif.jpg?alt=media&token=3c0ee7b4-3841-4318-ad13-7cee2131d10f'
                      url2='https://storage3.cracksealtech.com/pathfinding.gif?alt=media&token=ee8f5328-5d58-4269-ac42-bbca992b78ec'
                    />
                  </Col>
                  <Col xs={12} md={4} className='align-self-center'>
                    <h3>Intelligent Pathfinding</h3>
                    <div>
                      Intelligent pathfinding is important to allow for
                      efficient, high-speed, and high-quality sealant
                      application.
                    </div>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col>
                    <img
                      src='https://storage3.cracksealtech.com/pathfinding3.png?alt=media&token=f4a06a88-94fc-4713-87a1-940d1b1714aa'
                      className='img-fluid'
                      loading='lazy'
                      alt=''
                    />
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className='bg-transparent'>
                <Row className='d-flex'>
                  <Col xs={12} sm={6} md={8} className='align-self-center'>
                    <h3>Automated Sealant System</h3>
                    <div>
                      Use of a robotic system to clean the cracks and apply
                      sealant, allowing quick and safe application, without
                      requiring additional workers.
                    </div>
                  </Col>
                  <Col className='align-self-center'>
                    <figure>
                      <img
                        src='https://storage3.cracksealtech.com/008125_sealed.png?alt=media&token=97a03b61-e1c8-4021-9c6a-296a97d2d448'
                        className='figure-img img-fluid'
                        loading='lazy'
                        alt=''
                      />
                      <figcaption className='figure-caption'>
                        Simulated Sealed Cracks
                      </figcaption>
                    </figure>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Row>
        </Container>
      </Row>
      {/* <Row>
        <Container className='my-4'>
          <Row className='text-center'>
            <Col>
              <h2 className='mb-2'>Traditional Crack Sealing Process</h2>

              <Row className='d-flex py-4'>
                <Col xs={12} sm={6} md={4} className='align-self-top mt-3'>
                  <div className='h1'>
                    <span className='badge badge-secondary badge-pill'>1</span>
                  </div>
                  <h3>Cleaning using Hot Air Lance</h3>
                  <span className='h5 text-secondary'>
                    This removes debris, reduces any remaining moisture, and
                    heats the crack to allow for better sealant adhesion
                  </span>
                </Col>
                <Col xs={12} sm={6} md={4} className='align-self-top mt-3'>
                  <div className='h1'>
                    <span className='badge badge-secondary badge-pill'>2</span>
                  </div>
                  <h3>Sealing Cracks</h3>
                  <span className='h5 text-secondary'>
                    Any cracks greater than 1/8 inch and less than 1 and 1/2
                    inches should be sealed to prevent further damage.
                  </span>
                </Col>
                <Col xs={12} sm={6} md={4} className='align-self-top mt-3'>
                  <div className='h1'>
                    <span className='badge badge-secondary badge-pill'>3</span>
                  </div>
                  <h3>Blotting</h3>
                  <span className='h5 text-secondary'>
                    Depending on the speed of application, lane closures, and
                    road conditions, it may be necessary to apply sand, paper,
                    or soapy water on top of the sealant to prevent tracking of
                    sealant by vehicle tires.
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Row> */}
      {/* <Row className='bg-warning'>
        <Container className='my-4'>
          <h2 className='text-center mb-4'>Benefits of Crack Sealing</h2>

          <ListGroup flush className='mt-3'>
            <ListGroupItem className='bg-transparent'>
              <Row className='d-flex'>
                <Col
                  xs={4}
                  md={3}
                  lg={2}
                  className='align-self-center text-center'
                >
                  <img
                    src='/icons/iconmonstr-construction-23.svg'
                    className='img-fluid w-100'
                    style={{ maxWidth: 100 }}
                    alt=''
                  />
                </Col>
                <Col xs={8} md={9} lg={10} className='align-self-center'>
                  <span className='h5'>
                    A moisture and debris barrier, which prevents further
                    cracking in the road surface
                  </span>
                </Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem className='bg-transparent'>
              <Row className='d-flex'>
                <Col
                  xs={4}
                  md={3}
                  lg={2}
                  className='align-self-center text-center'
                >
                  <img
                    src='/icons/iconmonstr-calendar-9.svg'
                    className='img-fluid w-100'
                    style={{ maxWidth: 100 }}
                    alt=''
                  />
                </Col>
                <Col xs={8} md={9} lg={10} className='align-self-center'>
                  <span className='h5'>
                    Increases road lifetime by 1-3 years, and even longer for
                    lightly travelled roads
                  </span>
                </Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem className='bg-transparent'>
              <Row className='d-flex'>
                <Col
                  xs={4}
                  md={3}
                  lg={2}
                  className='align-self-center text-center'
                >
                  <img
                    src='/icons/iconmonstr-chart-18.svg'
                    className='img-fluid w-100'
                    style={{ maxWidth: 100 }}
                    alt=''
                  />
                </Col>
                <Col xs={8} md={9} lg={10} className='align-self-center'>
                  <span className='h5'>
                    Highest Cost-Benefit ratio out of all road preservation
                    options
                  </span>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Container>
      </Row> */}
      <Row>
        <Container className='my-4 text-center'>
          <h2 className='mb-4'>Challenges of Traditional Crack Sealing</h2>

          <Row className='my-3' noGutters>
            <Col xs={12} sm={6} lg={3}>
              <Card className='h-100 rounded-0 border-0'>
                <CardBody>
                  <CardTitle>
                    <h3 className='mb-0'>
                      Labor <br /> Intensive
                    </h3>
                  </CardTitle>
                  <CardText>
                    <img
                      src='/icons/iconmonstr-user-29.svg'
                      className='img-fluid w-100'
                      style={{ maxWidth: 120 }}
                      alt=''
                    />
                  </CardText>
                  <CardText>
                    <span className='h5 text-secondary'>
                      Standard Crew Size <br /> 5 people
                    </span>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <Card className='h-100 rounded-0 border-0'>
                <CardBody>
                  <CardTitle>
                    <h3 className='mb-0'>Inconsistent Application</h3>
                  </CardTitle>
                  <CardText>
                    <img
                      src='/icons/iconmonstr-chart-17.svg'
                      className='img-fluid w-100'
                      style={{ maxWidth: 120 }}
                      alt=''
                    />
                  </CardText>
                  <CardText>
                    <span className='h5 text-secondary'>
                      Reduced Effectiveness and Sealant Life
                    </span>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <Card className='h-100 rounded-0 border-0'>
                <CardBody>
                  <CardTitle>
                    <h3 className='mb-0'>
                      Safety <br /> Hazard
                    </h3>
                  </CardTitle>
                  <CardText>
                    <img
                      src='/icons/iconfinder_warning-2_293656.svg'
                      className='img-fluid w-100'
                      style={{ maxWidth: 120 }}
                      alt=''
                    />
                  </CardText>
                  <CardText>
                    <span className='h5 text-secondary'>
                      Exposure to Traffic and Hazardous Materials
                    </span>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <Card className='h-100 rounded-0 border-0'>
                <CardBody>
                  <CardTitle>
                    <h3>
                      Speed <br /> &nbsp;
                    </h3>
                  </CardTitle>
                  <CardText>
                    <img
                      src='/icons/iconmonstr-time-16.svg'
                      className='img-fluid w-100'
                      style={{ maxWidth: 120 }}
                      alt=''
                    />
                  </CardText>
                  <CardText>
                    <span className='h5 text-secondary'>
                      Only 2-3 Lane Miles per Day
                    </span>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>

      <Row className='bg-warning'>
        <Container>
          <Row className='text-center my-4'>
            <Col>
              <h2 className='mb-4'>Benefits</h2>

              <Row noGutters>
                <Col xs={12} md={6} className='p-3'>
                  <Card color='transparent' className='h-100 rounded-0'>
                    <CardBody>
                      <img
                        src='/icons/iconmonstr-currency-4.svg'
                        className='img-fluid w-25'
                        style={{ maxWidth: 120 }}
                        alt=''
                      />
                      <h3>Lowers Cost by Reducing Labor Needs</h3>
                      <span className='h5 text-secondary'>
                        Fewer workers needed to operate machinery. This allows
                        for more crews to work simultaneously, and makes travel
                        less of a factor
                      </span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} className='p-3'>
                  <Card color='transparent' className='h-100 rounded-0'>
                    <CardBody>
                      <img
                        src='/icons/iconmonstr-time-19.svg'
                        className='img-fluid w-25'
                        style={{ maxWidth: 120 }}
                        alt=''
                      />
                      <h3>Increases Efficiency</h3>
                      <span className='h5 text-secondary'>
                        Our machine uses automated equipment which can operate
                        at a higher speed. This allows for more mileage to be
                        completed in a day. This also reduces road closures.
                      </span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} className='p-3'>
                  <Card color='transparent' className='h-100 rounded-0'>
                    <CardBody>
                      <img
                        src='/icons/iconmonstr-certificate-9.svg'
                        className='img-fluid w-25'
                        style={{ maxWidth: 120 }}
                        alt=''
                      />
                      <h3>Increases Consistency and Quality</h3>
                      <span className='h5 text-secondary'>
                        Since all sealing is done robotically, sealant is
                        applied consistently, which results in high quality
                        results.
                      </span>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} md={6} className='p-3'>
                  <Card color='transparent' className='h-100 rounded-0'>
                    <CardBody>
                      <img
                        src='/icons/iconmonstr-shield-1-thin.svg'
                        className='img-fluid w-25'
                        style={{ maxWidth: 120 }}
                        alt=''
                      />
                      <h3>Reduces Safety Hazards</h3>
                      <span className='h5 text-secondary'>
                        Workers stay in the truck for the majority of the crack
                        sealing process, instead of being exposed to road
                        traffic. Reduced Lane Closures minimize risk to the
                        public.
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  </React.Fragment>
);

export { AutomatedSealing };

interface AutomatedSealingProps {
  noHeader?: boolean;
}
