import _ from 'lodash';
import { IDriveParameter } from '.';

export enum AdditionalStatusBit {
  NONE = 0,
  UNDER_VOLTAGE_ACTIVE = 1 << 0,
  MOTOR_OVERLOAD_ALARM = 1 << 1,
  DRIVE_OVER_TEMPERATURE_ALARM = 1 << 2,
  DRIVE_WARNING = 1 << 3,
  LOW_LOAD_DETECTED_ALARM = 1 << 4,
  LOCAL_KEYPAD_BATTERY_LOW = 1 << 5,
  REMOTE_KEYPAD_BATTERY_LOW = 1 << 6,
  AUTO_TUNE_ACTIVE = 1 << 7,
  LIMIT_SWITCH_ACTIVE = 1 << 8,
  FIRE_MODE_ACTIVE = 1 << 9,
}

export const AdditionalStatusBitNames = Object.freeze({
  [AdditionalStatusBit.NONE]: 'None',
  [AdditionalStatusBit.UNDER_VOLTAGE_ACTIVE]: 'Under Voltage Active',
  [AdditionalStatusBit.MOTOR_OVERLOAD_ALARM]:
    'Motor Overload Alarm or Inductor Overload Alarm',
  [AdditionalStatusBit.DRIVE_OVER_TEMPERATURE_ALARM]:
    'Drive Over-Temperature Alarm',
  [AdditionalStatusBit.DRIVE_WARNING]: 'Drive Warning',
  [AdditionalStatusBit.LOW_LOAD_DETECTED_ALARM]: 'Low Load Detected Alarm',
  [AdditionalStatusBit.LOCAL_KEYPAD_BATTERY_LOW]: 'Local Keypad battery Low',
  [AdditionalStatusBit.REMOTE_KEYPAD_BATTERY_LOW]: 'Remote Keypad battery Low',
  [AdditionalStatusBit.AUTO_TUNE_ACTIVE]: 'Auto-Tune Active',
  [AdditionalStatusBit.LIMIT_SWITCH_ACTIVE]: 'Limit Switch Active',
  [AdditionalStatusBit.FIRE_MODE_ACTIVE]: 'Fire Mode Active',
});

export class AdditionalStatusBitParameter implements IDriveParameter {
  public name: string;
  public value: AdditionalStatusBit;

  constructor(
    name: string = 'Additional Status Bits',
    value: AdditionalStatusBit = AdditionalStatusBit.NONE
  ) {
    this.name = name;
    this.value = value;
  }

  public get valueString() {
    let outputStringArray: string[] = [];
    _.forEach(AdditionalStatusBit, (value, key) => {
      if ((this.value & Number(value)) > 0)
        outputStringArray.push(AdditionalStatusBitNames[value]);
    });
    return outputStringArray.join(', ');
  }

  public toJSON() {
    return {
      ...this,
      valueString: this.valueString,
    };
  }

  public toString() {
    return this.valueString;
  }
}
