import _, { floor } from 'lodash';
import { Dictionary } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Jumbotron,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Table,
  UncontrolledCollapse,
  ButtonGroup,
  UncontrolledTooltip,
} from 'reactstrap';
// import {
//   HPlatform,
//   HMap,
//   HMapRoute,
//   HMapMarker,
// } from '@robinuit/react-here-maps-library';

import { ResponsiveBar } from '@nivo/bar';
import * as cx_default from 'classnames';
import { Fraction } from 'fractional';
import * as math from 'mathjs';
const cx = cx_default.default;

//const HOST = '192.168.2.101'; //192.168.2.235
//const HOST = '192.168.2.5:9099';
const HOST = 'reportserver.cracksealtech.com';

const groupList = _.orderBy(
  [
    // {
    //   name: 'Big Pine Subdivision (Pineknoll Dr, Pine Cone Dr, Big Pine Dr, Pine Hill Dr)',
    //   job_ids: [60, 65, 72, 73, 80, 85],
    // },
    // { name: 'Green St - BCRC', job_ids: [61, 112] },
    // { name: 'Hathaway Ct', job_ids: [62] },
    // { name: 'Parker Rd - BCRC', job_ids: [63, 111] },
    // { name: 'Nashville Rd', job_ids: [64, 102] },
    // { name: 'East State Rd (4 mi) - BCRC', job_ids: [66, 69] },
    // { name: 'Cloverdale Rd', job_ids: [68, 76] },
    // { name: 'Banfield Rd', job_ids: [71, 104] },
    // { name: 'East State Rd (2 mi)', job_ids: [74, 101] },
    // { name: 'Lochshore Rd - BCRC', job_ids: [75, 79] },
    // { name: 'Boniface Pt. Dr - BCRC', job_ids: [78, 108] },
    // { name: 'Stevens Wooded Acre', job_ids: [82] },
    // { name: 'Lawrence Rd', job_ids: [83, 89] },
    // { name: 'West State Rd', job_ids: [84, 105] },
    // { name: 'Enzian Rd - BCRC', job_ids: [86, 90] },
    // { name: 'Thornapple Lake Rd', job_ids: [88, 93] },
    // { name: 'Cory Dr', job_ids: [98] },
    // { name: 'Cordes Rd, Reynolds Rd, Margery Rd', job_ids: [99] },
    // { name: 'Marsh Rd', job_ids: [100, 107] },
    // { name: 'Kathryn Ct', job_ids: [109] },
    // { name: 'Heath Rd', job_ids: [94, 110] },
    // { name: 'Thornapple Valley Pines Dr', job_ids: [113] },
    // { name: 'Stevens Rd', job_ids: [92, 95] },
    { name: 'A -> G Take 1', job_ids: [164, 183] },
    { name: 'A -> G Take 2', job_ids: [163, 158] },
    { name: 'A -> G Take 3', job_ids: [172, 181] },
    { name: 'A -> G Take 4 (1 pass down center)', job_ids: [160] },
    { name: 'B Take 1', job_ids: [182] },
    { name: 'B Take 2', job_ids: [174] },
    { name: 'B Take 3', job_ids: [179] },
    { name: 'B Take 4', job_ids: [167] },
    { name: 'C Take 1', job_ids: [165] },
    { name: 'C Take 2', job_ids: [166] },
    { name: 'C Take 3', job_ids: [159] },
    { name: 'C Take 4', job_ids: [178] },
    { name: 'D Take 1', job_ids: [177] },
    { name: 'D Take 2', job_ids: [180] },
    { name: 'D Take 3', job_ids: [176] },
    { name: 'D Take 4', job_ids: [184] },
    { name: 'E Take 1', job_ids: [168] },
    { name: 'E Take 2', job_ids: [170] },
    { name: 'E Take 3', job_ids: [169] },
    { name: 'E Take 4', job_ids: [171] },
    { name: 'F Take 1', job_ids: [162] },
    { name: 'F Take 2', job_ids: [175] },
    { name: 'F Take 3', job_ids: [161] },
  ],
  'name'
);

interface ReportJob {
  id: number;
  typ: number;
  created_date: string;
  capture_date: string;
  calibration_date: string;
  road_width: number;
  road_length: number;
  road_sq_ft: number;
  lanes: number;
  lane_width: number;
  overband_width_in: number;
  overband_depth_in: number;
  crack_fill_depth_multiplier: number;
  mm_per_pixel: number;
  name: string;
  notes: string;
  start_bound: string;
  end_bound: string;
  is_doubled: boolean;
  reversed: boolean;
  temperature: number;
  state: string;
  county: string;
  direction: string;
}

interface ReportHistogram {
  bin: number;
  weight: number;
}

interface ReportPhoto {
  fuzzy_length: number;
  id: number;
  job_id: number;
  lat: number;
  lng: number;
  road_area: number;
  density: number;
  mean_crack_width: number;
  height: number;
  width: number;
  offroad_trim_left: number;
  offroad_trim_right: number;
  frame_number: number;
}

interface Report {
  jobs: Array<ReportJob>;
  photos: Array<ReportPhoto>;
  histogram_data: Array<ReportHistogram>;
  statistics: Array<number>;
  average_density: Array<number>;
}

interface FracButtonProps {
  valueUpdate: Function;
  min?: number;
  max?: number;
  step?: number;
  vals?: Array<number> | null;
  containerClass?: string;
  btnColor?: string;
  format?: (s: string) => string;
}

const defaultFracButtonProps: FracButtonProps = {
  valueUpdate: (v: number) => {
    console.log(v);
  },
  vals: [0, 1 / 32, 1 / 16, 1 / 8, 3 / 16, 1 / 4, 3 / 8, 1 / 2, 3 / 4, 1],
};

const FracButtons = (props: FracButtonProps = defaultFracButtonProps) => {
  const max = _.isNull(props.max)
    ? 1.5
    : (props.max ?? 1.5) + (props.step ?? 1 / 16);
  const vals =
    props.vals ?? _.range(props.min ?? 1 / 16, max, props.step ?? 1 / 16);

  return (
    <ButtonGroup size='sm' className={props.containerClass ?? 'w-100 mb-2'}>
      {_.map(vals, (v) => (
        <Button
          onClick={() => props.valueUpdate(v)}
          color={props.btnColor ?? 'outline-secondary'}
          key={v}
        >
          {_.isFunction(props.format)
            ? props.format(new Fraction(_.toNumber(v)).toString())
            : new Fraction(_.toNumber(v)).toString()}
        </Button>
      ))}
    </ButtonGroup>
  );
};

// const HEREMAPAPIKEY = 'RpZImZOsrKr253T61G0XySs220VOVKVeF37BvB5dVzA';
// const HEREMAPAPPID = 'eR4zcFrgFaSMQF1td86E';

const Report = () => {
  const [queryRes, updateQueryRes] = useState<Report | null>(null);
  const [jobList, updateJobList] = useState<Dictionary<ReportJob>>({});
  const [selectedJobIds, updateSelectedJobs] = useState<Array<number>>([
    164, 183,
  ]);
  const [filterJob, updateFilterJob] = useState<string>('');
  const [selectedGroup, updateSelectedGroup] = useState<Array<number>>([
    164, 183,
  ]); //actually array of jobids
  const [filterGroup, updateFilterGroup] = useState<string>('');

  const [requestedBy, updateRequestedBy] = useState('Crafco');
  const [roadSegment, updateRoadSegment] = useState('');
  const [roadSqFt, updateRoadSqFt] = useState('');
  const [minCrackWidth, updateMinCrackWidth] = useState('0.0');
  const [maxCrackWidth, updateMaxCrackWidth] = useState('3');
  const [overbandWidth, updateOverbandWidth] = useState('3');
  const [overbandDepth, updateOverbandDepth] = useState('0.125');
  const [binWidth, updateBinWidth] = useState('0.0625');
  const [widthAdjustmentMM, updateWidthAdjustmentMM] = useState('-2');
  const [crackFillDepthMult, updateCrackFillDepthMult] = useState('1');
  const [shouldGroup] = useState(false);
  const [showMultipleTable, updateShowMultipleTable] = useState(false);
  const defaultOctantWeights = [
    '0.5',
    '0.75',
    '1.0',
    '1.0',
    '1.0',
    '1.0',
    '0.75',
    '0.5',
  ];
  const [octantWeights, updateOctantWeights] = useState(
    _.clone(defaultOctantWeights)
  );
  const [photoBinCount, updatePhotoBinCount] = useState('1');
  const [lbsPerGal, updateLBSPerGal] = useState('10.5');
  const [multiplier, updateMultiplier] = useState('1.05');
  const [pendingRequest, updatePendingRequest] = useState(false);
  const [isDirty, updateIsDirty] = useState(false);
  const [startBound, updateStartBound] = useState('');
  const [endBound, updateEndBound] = useState('');
  const [minimumHistogramPercent, updateMinimumHistogramPercent] =
    useState('1');

  const [removeLaneOverlap, updateRemoveLaneOverlap] = useState(false);
  const [useBrokenQuery, updateUseBrokenQuery] = useState(true);

  useEffect(() => {
    const updateWeights = removeLaneOverlap
      ? ['1.0', '1.0', '1.0', '1.0', '1.0', '1.0', '1.0', '1.0']
      : _.clone(defaultOctantWeights);
    updateOctantWeights(updateWeights);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeLaneOverlap]);

  useEffect(() => {
    let req = fetch(`https://${HOST}/jobs?page=${0}&limit=${999}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    req
      .then((res) => res.json())
      .then((res: Array<ReportJob>) => {
        updateJobList(_.keyBy(res, 'id'));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const loadReport = useCallback(() => {
    const loadReportRequest = new Request(`https://${HOST}/report`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        job_ids: selectedJobIds,
        filter_min_in: minCrackWidth,
        filter_max_in: maxCrackWidth,
        overband_width_in: overbandWidth,
        overband_depth_in: overbandDepth,
        bin_width_in: binWidth,
        width_adjustment_mm: widthAdjustmentMM,
        crack_fill_depth_multiplier: crackFillDepthMult,
        octant_weights: octantWeights,
        multiplier: multiplier,
        road_sq_ft: roadSqFt,
        use_broken: useBrokenQuery,
      }),
    });

    if (!_.isEmpty(selectedJobIds)) {
      updatePendingRequest(true);
      fetch(loadReportRequest)
        .then((res) => res.json())
        .then((res) => {
          //test is_doubled and reversed
          // if (_.some(res['jobs'], (j) => j.id === 66)) {
          //   res['jobs'][0]['is_doubled'] = true;
          //   res['jobs'][0]['reversed'] = true;
          // }
          updateQueryRes(res);
          let binCount =
            res && res.photos && res.photos[0]
              ? floor(
                  ((shouldGroup ? res.jobs.length : 1) * 100) /
                    ((res?.photos[0]?.height * res?.jobs[0]?.mm_per_pixel) /
                      (25.4 * 12) /
                      (res?.jobs[0].is_doubled ? 2 : 1))
                )
              : 10;
          //clear overrides
          updateRoadSegment('');
          updateStartBound('');
          updateEndBound('');
          updatePhotoBinCount(`${binCount}`);
          updateIsDirty(false);
        })
        .then(() => updatePendingRequest(false))
        .catch((ex) => console.error(ex));
    }
  }, [
    selectedJobIds,
    minCrackWidth,
    maxCrackWidth,
    overbandWidth,
    overbandDepth,
    binWidth,
    widthAdjustmentMM,
    crackFillDepthMult,
    octantWeights,
    multiplier,
    roadSqFt,
    shouldGroup,
    useBrokenQuery,
  ]);

  useEffect(() => {
    if (!_.isEmpty(selectedJobIds)) loadReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJobIds]);

  useEffect(() => {
    updateIsDirty(true);
  }, [
    selectedJobIds,
    minCrackWidth,
    maxCrackWidth,
    overbandWidth,
    overbandDepth,
    binWidth,
    widthAdjustmentMM,
    crackFillDepthMult,
    octantWeights,
    multiplier,
    roadSqFt,
    lbsPerGal,
    useBrokenQuery,
  ]);

  useEffect(() => {
    if (shouldGroup && queryRes != null)
      updatePhotoBinCount(
        `${floor(_.toNumber(photoBinCount) * queryRes.jobs.length)}`
      );
    else if (queryRes != null)
      updatePhotoBinCount(
        `${Math.ceil(_.toNumber(photoBinCount) / queryRes.jobs.length)}`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldGroup]);

  const selectedJobs: Array<ReportJob> = useMemo(
    () =>
      selectedJobIds != null
        ? _.map(selectedJobIds, (jid) => jobList[jid])
        : [],
    [selectedJobIds, jobList]
  );

  const summed = useMemo(
    () => _.sumBy(queryRes?.histogram_data, (h) => h.weight),
    [queryRes]
  );

  const barData = useMemo(
    () =>
      queryRes == null
        ? null
        : _.map(
            _.filter(
              queryRes.histogram_data,
              (h: ReportHistogram) =>
                h.weight / summed >=
                Math.max(
                  0.0025,
                  (_.toNumber(minimumHistogramPercent) ?? 1) / 100
                )
            ),
            (h: ReportHistogram) => {
              return {
                id: h.bin,
                percent: math.round(100 * Math.min(h.weight / summed, 100), 1),
              };
            }
          ),
    [queryRes, minimumHistogramPercent, summed]
  );

  const densityStyle = (density: number) => {
    const ds = _.toNumber(multiplier) * density;
    return cx({
      'bg-success': ds < 50 / 1200,
      'bg-warning': ds < 150 / 1200 && ds >= 50 / 1200,
      'bg-danger': ds < 250 / 1200 && ds >= 150 / 1200,
      'bg-purple': ds >= 250 / 1200,
      'flex-fill': true,
      'border-left': true,
      'border-top': true,
      'border-bottom': true,
      'border-dark': true,
    });
  };

  const jobsById = useMemo(() => _.keyBy(queryRes?.jobs, 'id'), [queryRes]);
  const groupedPhotos = useMemo(
    () =>
      !shouldGroup
        ? _.toArray(_.groupBy(queryRes?.photos, 'job_id'))
        : [queryRes?.photos],
    [queryRes, shouldGroup]
  );

  const dirtyText = cx({
    'text-danger': isDirty,
  });

  const isAdmin = window.location.pathname.startsWith('/report-admin');
  const hiddenNotAdmin = cx({
    'd-none': !isAdmin,
  });

  const avg_width_mm = isAdmin
    ? _.sumBy(
        queryRes?.photos,
        (rp: ReportPhoto) =>
          (_.max([
            1,
            rp.mean_crack_width + (_.toNumber(widthAdjustmentMM) ?? 0),
          ]) ?? 0) *
          rp.fuzzy_length *
          jobsById[rp.job_id].mm_per_pixel
      ) / _.sumBy(queryRes?.photos, (rp: ReportPhoto) => rp.fuzzy_length)
    : 0;

  return (
    <Container fluid>
      <Row>
        <Col md={4} lg={3} xs={12} className='d-print-none'>
          <Row>
            <Col>
              <h4 className='mt-3'>
                Parameters {'\t'}
                <Button
                  size='sm'
                  color='secondary'
                  id='filter-collapse'
                  className='float-right'
                >
                  Show/Hide
                </Button>
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form disabled={pendingRequest}>
                <UncontrolledCollapse toggler='#filter-collapse'>
                  <FormGroup>
                    <Label for='roadSqFt'>Override Road Sq Ft.</Label>
                    <Input
                      type='number'
                      id='roadSqFt'
                      name='roadSqFt'
                      value={roadSqFt}
                      onChange={(e) => updateRoadSqFt(e.currentTarget.value)}
                      disabled={pendingRequest}
                      step={1}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='minCrackWidth'>Minimum Crack Width (in)</Label>
                    <FracButtons
                      valueUpdate={updateMinCrackWidth}
                      min={0}
                      max={1 / 4}
                      step={1 / 32}
                    />
                    <Input
                      type='number'
                      id='minCrackWidth'
                      name='minCrackWidth'
                      value={minCrackWidth}
                      onChange={(e) =>
                        updateMinCrackWidth(e.currentTarget.value)
                      }
                      disabled={pendingRequest}
                      step={1 / 32}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='maxCrackWidth'>Maximum Crack Width (in)</Label>
                    <FracButtons
                      valueUpdate={updateMaxCrackWidth}
                      min={1}
                      max={2}
                      step={1 / 8}
                    />
                    <Input
                      type='number'
                      id='maxCrackWidth'
                      name='maxCrackWidth'
                      value={maxCrackWidth}
                      onChange={(e) =>
                        updateMaxCrackWidth(e.currentTarget.value)
                      }
                      disabled={pendingRequest}
                      step={1 / 32}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='overbandWidth'>Overband Width (in)</Label>
                    <FracButtons
                      valueUpdate={updateOverbandWidth}
                      min={2}
                      max={5}
                      step={1}
                    />
                    <Input
                      type='number'
                      defaultValue={selectedJobs[0]?.overband_width_in}
                      id='overbandWidth'
                      name='overbandWidth'
                      value={overbandWidth}
                      onChange={(e) =>
                        updateOverbandWidth(e.currentTarget.value)
                      }
                      disabled={pendingRequest}
                      step={1}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='overbandDepth'>Overband Depth (in)</Label>
                    <FracButtons
                      valueUpdate={updateOverbandDepth}
                      min={1 / 16}
                      max={1 / 4}
                      step={1 / 32}
                    />
                    <Input
                      type='number'
                      defaultValue={selectedJobs[0]?.overband_depth_in}
                      id='overbandDepth'
                      name='overbandDepth'
                      value={overbandDepth}
                      onChange={(e) =>
                        updateOverbandDepth(e.currentTarget.value)
                      }
                      disabled={pendingRequest}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='lbsPerGal'>lbs. per gal</Label>
                    <Input
                      type='number'
                      id='lbsPerGal'
                      name='lbsPerGal'
                      value={lbsPerGal}
                      onChange={(e) => updateLBSPerGal(e.currentTarget.value)}
                      disabled={pendingRequest}
                      step={0.1}
                    />
                  </FormGroup>
                  <FormGroup className={hiddenNotAdmin}>
                    <Label for='widthAdjustmentMM'>
                      Crack Width Adjustment (pix)
                    </Label>
                    <Input
                      type='number'
                      id='widthAdjustmentMM'
                      name='widthAdjustmentMM'
                      value={widthAdjustmentMM}
                      onChange={(e) =>
                        updateWidthAdjustmentMM(e.currentTarget.value)
                      }
                      disabled={pendingRequest}
                      step={0.5}
                    />
                  </FormGroup>
                  <FormGroup className={hiddenNotAdmin}>
                    <Label for='overbandDepth'>
                      Crack Depth Multiplier (x width)
                    </Label>
                    <Input
                      type='number'
                      defaultValue={
                        selectedJobs[0]?.crack_fill_depth_multiplier
                      }
                      id='crackFillDepthMult'
                      name='crackFillDepthMult'
                      value={crackFillDepthMult}
                      onChange={(e) =>
                        updateCrackFillDepthMult(e.currentTarget.value)
                      }
                      step={0.1}
                      disabled={pendingRequest}
                    />
                  </FormGroup>
                  <FormGroup className={hiddenNotAdmin}>
                    <Label for='multiplier'>Multiplier</Label>
                    <Input
                      type='number'
                      id='multiplier'
                      name='multiplier'
                      value={multiplier}
                      onChange={(e) => updateMultiplier(e.currentTarget.value)}
                      disabled={pendingRequest}
                      step={0.05}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type='checkbox'
                      id='useBrokenQuery'
                      name='useBrokenQuery'
                      checked={useBrokenQuery}
                      onChange={(e) =>
                        updateUseBrokenQuery(e.currentTarget.checked)
                      }
                    />
                    <Label for='useBrokenQuery'>Use Old Query</Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type='checkbox'
                      id='removeLaneOverlap'
                      name='removeLaneOverlap'
                      checked={removeLaneOverlap}
                      onChange={(e) =>
                        updateRemoveLaneOverlap(e.currentTarget.checked)
                      }
                    />
                    <Label for='removeLaneOverlap'>Remove Lane Overlap</Label>
                  </FormGroup>
                </UncontrolledCollapse>
                <hr className={hiddenNotAdmin} />
                <h4 className={hiddenNotAdmin}>
                  Octant Weights{'\t'}
                  <Button
                    color='secondary'
                    id='octant-collapse'
                    size='sm'
                    className='float-right'
                  >
                    Show/Hide
                  </Button>
                </h4>
                <UncontrolledCollapse
                  toggler='#octant-collapse'
                  className={hiddenNotAdmin}
                >
                  {_.map(_.range(0, 8), (i) => (
                    <FormGroup inline key={i}>
                      <Input
                        type='number'
                        id={`octantWeights[${i}]`}
                        name={`octantWeights[${i}]`}
                        value={octantWeights[i]}
                        min={0}
                        max={1}
                        valid={
                          _.isNumber(octantWeights[i]) &&
                          _.toNumber(octantWeights[i]) <= 1 &&
                          _.toNumber(octantWeights) >= 0
                        }
                        onChange={(e) => {
                          let cpy = _.clone(octantWeights);
                          cpy[i] = e.currentTarget.value;
                          updateOctantWeights(cpy);
                        }}
                        disabled={pendingRequest}
                      />
                    </FormGroup>
                  ))}
                </UncontrolledCollapse>
                <hr />
                <h4>
                  Display Parameters{'\t'}
                  <Button
                    color='secondary'
                    id='field-collapse'
                    size='sm'
                    className='float-right'
                  >
                    Show/Hide
                  </Button>
                </h4>
                <UncontrolledCollapse toggler='#field-collapse'>
                  <FormGroup>
                    <Label for='requestedBy'>Requested By</Label>
                    <Input
                      type='text'
                      id='requestedBy'
                      name='requestedBy'
                      value={requestedBy}
                      onChange={(e) => updateRequestedBy(e.currentTarget.value)}
                      disabled={pendingRequest}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='roadSegment'>Road Segment (override)</Label>
                    <Input
                      type='text'
                      id='roadSegment'
                      name='roadSegment'
                      value={roadSegment ?? queryRes?.jobs[0].name}
                      onChange={(e) => updateRoadSegment(e.currentTarget.value)}
                      disabled={pendingRequest}
                    />
                  </FormGroup>
                  <FormGroup className={hiddenNotAdmin}>
                    <Label for='roadSegment'>Start Bound (override)</Label>
                    <Input
                      type='text'
                      id='startBound'
                      name='startBound'
                      value={startBound ?? queryRes?.jobs[0].start_bound}
                      onChange={(e) => updateStartBound(e.currentTarget.value)}
                      disabled={pendingRequest}
                    />
                  </FormGroup>
                  <FormGroup className={hiddenNotAdmin}>
                    <Label for='roadSegment'>End Bound (override)</Label>
                    <Input
                      type='text'
                      id='endBound'
                      name='endBound'
                      value={endBound ?? queryRes?.jobs[0].end_bound}
                      onChange={(e) => updateEndBound(e.currentTarget.value)}
                      disabled={pendingRequest}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='binWidth'>Histogram Bin Width (in)</Label>
                    <FracButtons
                      valueUpdate={updateBinWidth}
                      min={1 / 32}
                      max={1 / 4}
                      step={1 / 32}
                    />
                    <Input
                      type='number'
                      id='binWidth'
                      name='binWidth'
                      value={binWidth}
                      onChange={(e) => updateBinWidth(e.currentTarget.value)}
                      disabled={pendingRequest}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='minimumHistogramPercent'>
                      Minimum Histogram Percent
                    </Label>
                    <FracButtons
                      valueUpdate={updateMinimumHistogramPercent}
                      vals={[1 / 4, 1 / 2, 1, 2]}
                    />
                    <Input
                      type='number'
                      id='minimumHistogramPercent'
                      name='minimumHistogramPercent'
                      value={minimumHistogramPercent}
                      onChange={(e) =>
                        updateMinimumHistogramPercent(e.currentTarget.value)
                      }
                      disabled={pendingRequest}
                      step={0.1}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for='photoBinCount'>Photo (Density) Bin Count</Label>
                    <FracButtons
                      valueUpdate={(v: number) =>
                        updatePhotoBinCount(
                          `${Math.max(1, floor(_.toNumber(photoBinCount) * v))}`
                        )
                      }
                      vals={[0, 1 / 2, 2]}
                      format={(s) => `x ${s}`}
                    />
                    <Input
                      type='number'
                      id='photoBinCount'
                      name='photoBinCount'
                      value={photoBinCount}
                      onChange={(e) =>
                        updatePhotoBinCount(e.currentTarget.value)
                      }
                      step={1}
                      valid={_.isInteger(photoBinCount)}
                    />
                  </FormGroup>
                  {/* <FormGroup check inline>
                    <Input
                      type='checkbox'
                      id='shouldGroup'
                      name='shouldGroup'
                      checked={shouldGroup}
                      onChange={(e) =>
                        updateShouldGroup(e.currentTarget.checked)
                      }
                    />
                    <Label for='shouldGroup'>Group Density Graphs?</Label>
                  </FormGroup> */}
                  <FormGroup check inline>
                    <Input
                      type='checkbox'
                      id='showMultipleTable'
                      name='showMultipleTable'
                      checked={showMultipleTable}
                      onChange={(e) =>
                        updateShowMultipleTable(e.currentTarget.checked)
                      }
                    />
                    <Label for='showMultipleTable'>
                      Show All Segments in Table?
                    </Label>
                  </FormGroup>
                </UncontrolledCollapse>
              </Form>
              <hr />
              <Button
                color={isDirty ? 'danger' : 'primary'}
                onClick={loadReport}
                disabled={pendingRequest}
                className='my-2'
              >
                Update
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <h2>
                Job List{' '}
                <Button
                  size='sm'
                  onClick={() => {
                    updateSelectedGroup([]);
                    updateSelectedJobs([]);
                    updateFilterGroup('');
                  }}
                >
                  Clear
                </Button>{' '}
                <Button
                  color='secondary'
                  id='group-list-collapse'
                  size='sm'
                  className='float-right'
                >
                  Show/Hide
                </Button>
              </h2>
            </Col>
          </Row>
          <UncontrolledCollapse toggler='group-list-collapse' defaultOpen>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    type='text'
                    name='filterGroup'
                    id='filterGroup'
                    placeholder='Search ...'
                    value={filterGroup}
                    onChange={(e) => updateFilterGroup(e.currentTarget.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                <ListGroup>
                  {_.map(
                    _.filter(groupList, (j) =>
                      _.includes(
                        j.name?.toLowerCase() ?? '',
                        filterGroup.toLowerCase()
                      )
                    ),
                    (j, i) => (
                      <ListGroupItem
                        tag='button'
                        action
                        key={_.uniqueId()}
                        active={_.isEqual(selectedGroup, j.job_ids)}
                        onClick={() => {
                          updateSelectedGroup(j.job_ids);
                          updateSelectedJobs(j.job_ids);
                        }}
                      >
                        {i + 1}. {j.name}
                      </ListGroupItem>
                    )
                  )}
                </ListGroup>
              </Col>
            </Row>
          </UncontrolledCollapse>
          <Row className={hiddenNotAdmin}>
            <Col>
              <hr />
              <h2>
                Individual Job List ({selectedJobIds.length}){' '}
                <Button
                  size='sm'
                  onClick={() => {
                    updateSelectedJobs([]);
                    updateFilterJob('');
                  }}
                >
                  Clear
                </Button>{' '}
                <Button
                  color='secondary'
                  id='job-list-collapse'
                  size='sm'
                  className='float-right'
                >
                  Show/Hide
                </Button>
              </h2>
            </Col>
          </Row>
          <UncontrolledCollapse
            toggler='job-list-collapse'
            className={hiddenNotAdmin}
          >
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    type='text'
                    name='filterJob'
                    id='filterJob'
                    placeholder='Search ...'
                    value={filterJob}
                    onChange={(e) => updateFilterJob(e.currentTarget.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                <ListGroup>
                  {_.map(
                    _.filter(jobList, (j) =>
                      _.includes(
                        j.name?.toLowerCase() ?? '',
                        filterJob.toLowerCase()
                      )
                    ),
                    (j) => (
                      <ListGroupItem
                        tag='button'
                        action
                        key={j.id}
                        active={_.includes(selectedJobIds, j.id)}
                        onClick={() => {
                          let selectedJobs = selectedJobIds.includes(j.id)
                            ? _.without(selectedJobIds, j.id)
                            : [...selectedJobIds, j.id];
                          updateSelectedJobs(selectedJobs);
                        }}
                      >
                        {j.id}. {j.name}
                      </ListGroupItem>
                    )
                  )}
                </ListGroup>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </Col>
        <Col className={dirtyText}>
          {/* <Row>
            <Col>
              <img
                src='/logo-black.jpg'
                className='responsive'
                style={{ maxWidth: '20%' }}
              />
            </Col>
          </Row> */}
          <Row className='d-print-none'>
            <Col>
              <img
                src='https://storage3.cracksealtech.com/crafco-road-map2.jpg'
                className='img-fluid img-thumbnail'
                alt='map of road'
              />
            </Col>
          </Row>
          <Row className='d-print-none my-2'>
            <Col>
              <Alert color='warning'>
                NOTE: DOES NOT INCLUDE PERIMETER "CRACK" IN ANY OF THE FOLLOWING
                NUMBERS/GRAPHICS
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className='d-inline'>Road Segment Quality Report</h1>

              <img
                src='/logo-black.jpg'
                className='responsive float-right'
                style={{ maxWidth: '20%' }}
                alt='logo'
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {isDirty && (
                <Alert color='danger'>
                  <b>Parameters have been updated. Click update to refresh.</b>{' '}
                  <Button
                    type='button'
                    color='secondary'
                    size='sm'
                    onClick={() => updateIsDirty(false)}
                  >
                    Ignore
                  </Button>{' '}
                  <Button
                    color={isDirty ? 'danger' : 'primary'}
                    onClick={loadReport}
                    disabled={pendingRequest}
                    size='sm'
                  >
                    Update
                  </Button>
                </Alert>
              )}
              <Table
                bordered
                responsive
                size='sm'
                className={`w-100 my-4 ${dirtyText}`}
              >
                <tbody>
                  <tr>
                    <th scope='row'>Requesting Agency</th>
                    <td>{requestedBy}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Road Segment</th>
                    {showMultipleTable ? (
                      <td>
                        {_.isEmpty(roadSegment)
                          ? _.join(
                              _.map(queryRes?.jobs, (j) => j.name),
                              ', '
                            )
                          : roadSegment}
                      </td>
                    ) : (
                      <td>
                        {_.isEmpty(roadSegment)
                          ? queryRes?.jobs[0].name
                          : roadSegment}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th scope='row'>State</th>
                    <td>{queryRes?.jobs[0].state}</td>
                  </tr>
                  <tr>
                    <th scope='row'>County</th>
                    <td>{queryRes?.jobs[0].county}</td>
                  </tr>
                  {/* <tr>
                    <th scope='row'>Direction</th>
                    <td>
                      {_.join(
                        _.map(queryRes?.jobs, (j) => j.direction),
                        ', '
                      )}
                    </td>
                  </tr> */}
                  {(_.isEmpty(startBound) &&
                    _.isEmpty(queryRes?.jobs[0].start_bound)) || (
                    <tr>
                      <th scope='row'>Start Bound</th>
                      {/* <td>
                      {_.isEmpty(startBound)
                        ? _.join(
                            _.map(queryRes?.jobs, (j) => j.start_bound),
                            ', '
                          )
                        : startBound}
                    </td> */}
                      <td>
                        {_.isEmpty(startBound)
                          ? queryRes?.jobs[0].start_bound
                          : startBound}
                      </td>
                    </tr>
                  )}
                  {(_.isEmpty(endBound) &&
                    _.isEmpty(queryRes?.jobs[0].end_bound)) || (
                    <tr>
                      <th scope='row'>End Bound</th>
                      {/* <td>
                      {_.isEmpty(endBound)
                        ? _.join(
                            _.map(queryRes?.jobs, (j) => j.end_bound),
                            ', '
                          )
                        : endBound}
                    </td> */}
                      <td>
                        {_.isEmpty(endBound)
                          ? queryRes?.jobs[0].end_bound
                          : endBound}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th scope='row'>Road Width</th>
                    {showMultipleTable ? (
                      <td>
                        {_.join(
                          _.map(queryRes?.jobs, (j) => `${j.road_width} ft`),
                          ', '
                        )}{' '}
                        ft
                      </td>
                    ) : (
                      <td>{queryRes?.jobs[0].road_width} ft</td>
                    )}
                  </tr>
                  <tr>
                    <th scope='row'>Road Length</th>
                    {showMultipleTable ? (
                      <td>
                        {_.join(
                          _.map(queryRes?.jobs, (j) => `${j.road_length} mi`),
                          ', '
                        )}
                      </td>
                    ) : (
                      <td>{queryRes?.jobs[0].road_length} mi</td>
                    )}
                  </tr>
                  <tr>
                    <th scope='row'>Road Sq Ft</th>
                    {showMultipleTable ? (
                      <td>
                        {_.join(
                          _.map(queryRes?.jobs, (j) => `${j.road_sq_ft} sq ft`),
                          ', '
                        )}
                      </td>
                    ) : (
                      <td>{queryRes?.jobs[0].road_sq_ft} sq ft</td>
                    )}
                  </tr>
                  {/* <tr>
                    <th scope='row'>Number of Lanes</th>
                    <td>{queryRes?.jobs[0]?.lanes}</td>
                  </tr> */}

                  {/*<tr>
                    <th scope='row'>Temperature (air)</th>
                     <td>
                      {_.join(
                        _.map(
                          queryRes?.jobs,
                          (j) => `${j.temperature ?? 'N/A'} °F`
                        ),
                        ', '
                      )}
                    </td>
                    <td>{queryRes?.jobs[0].temperature ?? 'N/A'} °F</td>
                  </tr> */}
                  {/* <tr>
                    <th scope='row'>Temperature (pavement)</th>
                    <td>
                      {_.join(
                        _.map(
                          queryRes?.jobs,
                          (j) => `${j.temperature ?? 'N/A'} °F`
                        ),
                        ', '
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <th scope='row'>Minimum Crack Width Filter</th>
                    <td>{new Fraction(Number(minCrackWidth)).toString()} in</td>
                  </tr>
                  <tr>
                    <th scope='row'>Maximum Crack Width Filter</th>
                    <td>{new Fraction(Number(maxCrackWidth)).toString()} in</td>
                  </tr>
                  {/* <tr>
                    <th scope='row'>Temperature at Acquistion</th>
                    <td>{'81 deg. F'}</td>
                  </tr> */}
                  <tr>
                    <th scope='row'>Overband Width</th>
                    <td>
                      {new Fraction(
                        Number(
                          overbandWidth ?? queryRes?.jobs[0]?.overband_width_in
                        )
                      ).toString()}{' '}
                      in
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>Overband Depth</th>
                    <td>
                      {new Fraction(
                        Number(
                          overbandDepth ?? queryRes?.jobs[0]?.overband_depth_in
                        )
                      ).toString()}{' '}
                      in
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>Acquisition Date</th>
                    {showMultipleTable ? (
                      <td>
                        {_.join(
                          _.map(queryRes?.jobs, (j) =>
                            j?.capture_date != null
                              ? new Date(
                                  j?.capture_date.replace(/ /g, 'T') ??
                                    Date.now()
                                ).toLocaleString()
                              : 'N/A'
                          ),
                          ', '
                        )}
                      </td>
                    ) : (
                      <td>
                        {queryRes?.jobs[0].capture_date != null
                          ? new Date(
                              queryRes?.jobs[0]?.capture_date.replace(
                                / /g,
                                'T'
                              ) ?? Date.now()
                            ).toLocaleString()
                          : 'N/A'}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th scope='row'>Calibration Date</th>
                    <td>
                      {queryRes?.jobs[0]?.calibration_date != null
                        ? new Date(
                            queryRes?.jobs[0]?.calibration_date.replace(
                              / /g,
                              'T'
                            ) ?? Date.now()
                          ).toLocaleString()
                        : 'N/A'}
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>Report Date</th>
                    <td>{new Date(Date.now()).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th scope='row'>Notes</th>
                    <td>
                      {_.map(queryRes?.jobs, (j) => (
                        <div key={j.id}>{j.notes}</div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <h2>Location</h2>
              <div>
                <b>Start Position:</b>{' '}
                {(queryRes && _.first([...queryRes?.photos])?.lat) ?? ''}
                {(queryRes && _.first([...queryRes.photos])?.lng) ?? ''}
              </div>
              <div>
                <b>End Position:</b>
                {(queryRes && _.last([...queryRes?.photos])?.lat) ?? ''}
                {(queryRes && _.last([...queryRes?.photos])?.lng) ?? ''}
              </div>
            </Col>
            <Col>
              <HPlatform
                apikey={HEREMAPAPIKEY}
                app_id={HEREMAPAPPID}
                app_code={HEREMAPAPIKEY}
              >
                <HMap
                  mapOptions={{
                    center: { lat: 10.998666, lng: -63.79841 },
                    zoom: 12,
                  }}
                />
              </HPlatform>
            </Col>
          </Row> */}

          <Row>
            <Col md={4}>
              <h3>Analysis Results </h3>
              <div className='mb-2'>
                <h5>Cracking Length</h5>
                <li>
                  {math.round((queryRes?.statistics[1] ?? 0) / (25.4 * 12), 2)}{' '}
                  <b>ft</b>
                </li>
                <li>
                  {math.round(
                    (queryRes?.statistics[1] ?? 0) / (25.4 * 12 * 3),
                    2
                  )}{' '}
                  <b>yd</b>
                </li>
                <li>
                  {math.round((queryRes?.statistics[1] ?? 0) / 1000, 2)}{' '}
                  <b>m</b>
                </li>
              </div>
              {isAdmin && (
                <div className='mb-2'>
                  <h5>Average Crack Width</h5>
                  <li>
                    {math.round(avg_width_mm, 2)} <b>mm</b>
                  </li>
                  <li>
                    {math.round(avg_width_mm / 25.4, 2)} <b>in</b>
                  </li>
                </div>
              )}
              <div>
                <h5>Crack Density: </h5>
                <ul>
                  <li>
                    {queryRes?.average_density != null
                      ? math.round(
                          queryRes?.average_density[0] /
                            25.4 /
                            (queryRes?.average_density[1] / (25.4 * 12) ** 2),
                          2
                        )
                      : 0}{' '}
                    <b>
                      in/ft<sup>2</sup>
                    </b>
                  </li>
                  <li>
                    {queryRes?.average_density != null
                      ? math.round(
                          queryRes?.average_density[0] /
                            25.4 /
                            (queryRes?.average_density[1] /
                              (25.4 * 12 * 3) ** 2),
                          2
                        )
                      : 0}{' '}
                    <b>
                      in/yd<sup>2</sup>
                    </b>
                  </li>
                  <li>
                    {queryRes?.average_density != null
                      ? math.round(
                          queryRes?.average_density[0] /
                            10 /
                            (queryRes?.average_density[1] / 1000000),
                          2
                        )
                      : 0}{' '}
                    <b>
                      cm/m<sup>2</sup>
                    </b>
                  </li>
                </ul>
              </div>
              <div className={' '}>
                {/*<div>
                  <Badge color='danger w-100'>* calibration required *</Badge>
                </div>*/}
                <h5>Projected Sealant Volume:</h5>
                {math.round(queryRes?.statistics[2] ?? 0, 2)} gal (
                {math.round(
                  (queryRes?.statistics[2] ?? 0) *
                    (_.toNumber(lbsPerGal) ?? 10),
                  2
                )}{' '}
                lbs @ {_.toNumber(lbsPerGal) ?? 10}lbs/gal)
              </div>
            </Col>
            <Col md={8}>
              <div className='border'>
                <h5 className='text-center'>Crack Width Histogram</h5>
                {barData == null || pendingRequest ? (
                  <Jumbotron>
                    <h1>Waiting for data to load ...</h1>
                  </Jumbotron>
                ) : (
                  <div style={{ maxHeight: 400, height: 400 }}>
                    <ResponsiveBar
                      data={barData}
                      indexBy='id'
                      keys={['percent']}
                      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                      label={(d) => `${d.value} %`}
                      axisBottom={{
                        format: (d) =>
                          `${new Fraction(
                            Number(d) - Number(binWidth)
                          )}-${new Fraction(d)}`,
                        legend: 'Width of Crack (in)',
                        legendPosition: 'middle',
                        legendOffset: 40,
                      }}
                      axisLeft={{
                        legend: 'Amount of Cracking (%)',
                        legendPosition: 'middle',
                        legendOffset: -40,
                      }}
                      colors={['#70bdff']}
                      // markers={[
                      //   {
                      //     axis: 'x',
                      //     value: minCrackWidth + '',
                      //     legend: `min ${minCrackWidth} in`,
                      //   },
                      //   {
                      //     axis: 'x',
                      //     value: 100 + maxCrackWidth + '',
                      //     legend: `min ${maxCrackWidth} in`,
                      //   },
                      // ]}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>
                Crack Density{' '}
                {shouldGroup && (queryRes?.jobs.length ?? 1) > 1
                  ? ''
                  : '(Per Lane)'}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              {pendingRequest || _.isEmpty(groupedPhotos) ? (
                <Jumbotron>
                  <h1>Waiting for data to load ...</h1>
                </Jumbotron>
              ) : (
                _.map(groupedPhotos, (g: ReportPhoto[], key) => {
                  let ordered = _.orderBy(g, (p: ReportPhoto) =>
                    jobsById[p.job_id].reversed
                      ? -1 * p.frame_number
                      : p.frame_number
                  );
                  const chunks = _.chunk(
                    ordered,
                    _.toNumber(photoBinCount) ?? 1
                  );
                  const reduced = _.map(
                    chunks,
                    (c) =>
                      _.meanBy(c, (c) => c.fuzzy_length / (25.4 * 12)) /
                      _.meanBy(c, (c) => c.road_area / Math.pow(25.4 * 12, 2))
                  );

                  return (
                    <Row>
                      <Col>
                        {!shouldGroup &&
                          queryRes != null &&
                          (queryRes?.jobs.length ?? 1) > 1 && (
                            <div>
                              <h5>
                                Lane {key + 1}{' '}
                                <small className='text-secondary'>
                                  <b>
                                    ( {jobsById[g[0].job_id].name}{' '}
                                    {jobsById[g[0].job_id].direction} )
                                  </b>
                                </small>
                              </h5>
                            </div>
                          )}
                        {(_.isEmpty(startBound) &&
                          _.isEmpty(jobsById[g[0].job_id].start_bound) &&
                          _.isEmpty(endBound) &&
                          _.isEmpty(jobsById[g[0].job_id].end_bound)) || (
                          <div className='w-100'>
                            <span>
                              <b>|</b>{' '}
                              {_.isEmpty(startBound)
                                ? jobsById[g[0].job_id].start_bound
                                : startBound}
                              &nbsp;
                            </span>
                            <span className='float-right'>
                              &nbsp;
                              {_.isEmpty(endBound)
                                ? jobsById[g[0].job_id].end_bound
                                : endBound}{' '}
                              <b>|</b>
                            </span>
                          </div>
                        )}
                        <div className='d-flex w-100'>
                          {_.map(reduced, (p: number, i: number) => (
                            <React.Fragment key={`segment-${key}-${i}`}>
                              <div
                                style={{ height: 50 }}
                                id={`segment-${key}-${i}`}
                                className={
                                  densityStyle(p) +
                                  (i === reduced.length - 1
                                    ? ' border-right'
                                    : '')
                                }
                              ></div>
                              <UncontrolledTooltip
                                target={`segment-${key}-${i}`}
                              >
                                {math.round(p, 4)} ft/ft<sup>2</sup>
                              </UncontrolledTooltip>
                            </React.Fragment>
                          ))}
                        </div>
                        <div>
                          <span className='text-secondary float-left'>
                            Each section represents{' '}
                            {photoBinCount &&
                            queryRes &&
                            queryRes.photos &&
                            queryRes.photos[0]
                              ? math.round(
                                  (Number(photoBinCount) *
                                    g[0]?.height *
                                    jobsById[g[0].job_id]?.mm_per_pixel) /
                                    (25.4 * 12)
                                ) /
                                (shouldGroup ? queryRes.jobs.length : 1) /
                                (jobsById[g[0].job_id].is_doubled ? 2 : 1)
                              : 'N/A'}{' '}
                            ft. of roadway
                          </span>
                          <span className='float-right my-2'>
                            <span
                              className='bg-success d-inline-block mx-2'
                              style={{ width: 16, height: 16 }}
                            ></span>{' '}
                            Low{' '}
                            <span
                              className='bg-warning d-inline-block mx-2'
                              style={{ width: 16, height: 16 }}
                            ></span>{' '}
                            Medium{' '}
                            <span
                              className='bg-danger d-inline-block mx-2'
                              style={{ width: 16, height: 16 }}
                            ></span>{' '}
                            High
                            <span
                              className='bg-purple d-inline-block mx-2'
                              style={{ width: 16, height: 16 }}
                            ></span>{' '}
                            Very High
                          </span>
                        </div>
                      </Col>
                    </Row>
                  );
                })
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col className='text-danger mt-4'>
              *Note: This is a preliminary report without doing the necessary
              calibration. Numbers provided may not be representative of final
              calibrated outcomes (especially sealant volume).
            </Col>
          </Row> */}
          <Row className='mt-2'>
            <Col>
              <b>
                © {new Date(Date.now()).getFullYear()} Crack Seal Technologies
              </b>
              <b className='float-right'>
                reporting@cracksealtech.com | (616) 304-4220
              </b>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export { Report };
