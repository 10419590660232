import React from 'react';
import { Container } from 'reactstrap';

const Contact = () => {
  return (
    <Container>
      <a href='mailto: info@cracksealtech.com'>
        Contact us at info@cracksealtech.com
      </a>
    </Container>
  );
};

export { Contact };
