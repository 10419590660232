import { IDriveParameter } from '.';

export enum AMCReferenceSelect {
  STOP = 0,
  POSITION_ABS = 1,
  SPEED = 2,
  CAM = 3,
  EGB = 4,
  HOME = 5,
  POSITION_REF = 6,
  POSITION_PROFILE = 7,
}

export const AMCReferenceSelectNames = Object.freeze({
  [AMCReferenceSelect.STOP]: 'Stop',
  [AMCReferenceSelect.POSITION_ABS]: 'Position Abs',
  [AMCReferenceSelect.SPEED]: 'Speed',
  [AMCReferenceSelect.CAM]: 'Cam',
  [AMCReferenceSelect.EGB]: 'EGB',
  [AMCReferenceSelect.HOME]: 'Home',
  [AMCReferenceSelect.POSITION_REF]: 'Position Ref',
  [AMCReferenceSelect.POSITION_PROFILE]: 'Position Profile',
});

export class AMCReferenceSelectParameter implements IDriveParameter {
  public name: string;
  public value: AMCReferenceSelect;

  constructor(
    name: string = 'AMC Reference Select',
    value: AMCReferenceSelect = AMCReferenceSelect.STOP
  ) {
    this.name = name;
    this.value = value;
  }

  public get valueString() {
    return AMCReferenceSelectNames[this.value];
  }

  public toJSON() {
    return {
      ...this,
      valueString: this.valueString,
    };
  }

  public toString() {
    return this.valueString;
  }
}
