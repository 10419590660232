import React from 'react';
import { Col, Row } from 'reactstrap';

const MiniHeader = () => (
  <Row
    className=''
    style={{
      backgroundImage:
        "url('https://storage3.cracksealtech.com/road-crack-large.jpg?alt=media&token=daecb066-a649-437d-98b1-271fb77a630c')",
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundPositionY: '-450px',
      opacity: 0.9,
      height: '25vh',
    }}
  >
    <Col className='text-center align-self-center'>
      <h1 style={{ textShadow: '0px 0px 10px black' }} className='text-white'>
        <img
          src='logo-white.png'
          className='fluid'
          style={{
            maxWidth: 480,
            width: '100%',
          }}
          alt=''
        />
      </h1>
    </Col>
  </Row>
);

export { MiniHeader };
