import React from 'react';
import _, { Dictionary } from 'lodash';
import { firestore } from 'firebase';
import classNames from 'classnames';
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap';
import { withFirebase, FirebaseProps } from '../config';
import { AMCReferenceSelect } from '../models/parameters/AMCReferenceSelectParameter';
import {
  AdditionalStatusBit,
  AMCStatus,
  DriveData,
  DriveStatus,
  IDriveParameter,
  StatusWord,
} from '../models';

interface FirestoreDocumentData {
  [key: string]: object;
}

interface DriveDocumentData {
  id: string;
  data: DriveData;
}

interface HomeState extends Object {
  drive1: Dictionary<DriveDocumentData>;
  drive2: Dictionary<DriveDocumentData>;
  drive1Listener: () => void;
  drive2Listener: () => void;
}

//use property key
interface IDynamicObject {
  [key: string]: any;
}

const contextualClassNames: IDynamicObject = {
  hardwareEnable: (value: boolean, ...otherClasses: string[]) =>
    classNames(otherClasses, {
      'list-group-item-danger': !value,
      'list-group-item-success': value,
    }),
  statusWord: (value: StatusWord, ...otherClasses: string[]) =>
    classNames(otherClasses, {
      'list-group-item-danger': (value & StatusWord.DRIVE_HEALTHY) === 0,
      'list-group-item-success': (value & StatusWord.DRIVE_HEALTHY) > 0,
    }),
  driveStatus: (value: DriveStatus, ...otherClasses: string[]) =>
    classNames(otherClasses, {
      'list-group-item-danger': [
        DriveStatus.HEAT,
        DriveStatus.INHIBIT,
        DriveStatus.OFF,
        DriveStatus.SUPPLY_LOSS,
        DriveStatus.TRIP,
        DriveStatus.UNDER_VOLTAGE,
      ].includes(value),
      'list-group-item-success': ![
        DriveStatus.HEAT,
        DriveStatus.INHIBIT,
        DriveStatus.OFF,
        DriveStatus.SUPPLY_LOSS,
        DriveStatus.TRIP,
        DriveStatus.UNDER_VOLTAGE,
      ].includes(value),
    }),
  amcStatus: (value: AMCStatus, ...otherClasses: string[]) =>
    classNames(otherClasses, {
      'list-group-item-danger':
        (value &
          (AMCStatus.FOLLOWING_ERROR |
            AMCStatus.FOLLOWING_ERROR_STOP |
            AMCStatus.HOME_REQUIRED |
            AMCStatus.MASTER_SPEED_TOO_HIGH |
            AMCStatus.NEGATIVE_HARDWARE_LIMIT |
            AMCStatus.NEGATIVE_SOFTWARE_LIMIT |
            AMCStatus.POSITIVE_HARDWARE_LIMIT |
            AMCStatus.POSITIVE_SOFTWARE_LIMIT)) >
        0,
      'list-group-item-success':
        (value &
          (AMCStatus.FOLLOWING_ERROR |
            AMCStatus.FOLLOWING_ERROR_STOP |
            AMCStatus.HOME_REQUIRED |
            AMCStatus.MASTER_SPEED_TOO_HIGH |
            AMCStatus.NEGATIVE_HARDWARE_LIMIT |
            AMCStatus.NEGATIVE_SOFTWARE_LIMIT |
            AMCStatus.POSITIVE_HARDWARE_LIMIT |
            AMCStatus.POSITIVE_SOFTWARE_LIMIT)) ===
        0,
    }),
  run: (value: boolean, ...otherClasses: string[]) =>
    classNames(otherClasses, {
      'list-group-item-danger': !value,
      'list-group-item-success': value,
    }),
  additionalStatusBit: (
    value: AdditionalStatusBit,
    ...otherClasses: string[]
  ) =>
    classNames(otherClasses, {
      'list-group-item-danger':
        (value &
          (AdditionalStatusBit.DRIVE_OVER_TEMPERATURE_ALARM |
            AdditionalStatusBit.DRIVE_WARNING |
            AdditionalStatusBit.LOW_LOAD_DETECTED_ALARM |
            AdditionalStatusBit.MOTOR_OVERLOAD_ALARM |
            AdditionalStatusBit.UNDER_VOLTAGE_ACTIVE)) >
        0,
      'list-group-item-success': value === 0,
    }),
  amcReferenceSelect: (value: AMCReferenceSelect, ...otherClasses: string[]) =>
    classNames(otherClasses, {
      'list-group-item-danger': value & AMCReferenceSelect.STOP,
    }),
};

class DriveListComponent extends React.Component<
  FirebaseProps & React.PropsWithChildren<object>,
  HomeState
> {
  constructor(props: FirebaseProps & React.PropsWithChildren<object>) {
    super(props);

    this.state = {
      drive1: {},
      drive2: {},
      drive1Listener: () => {},
      drive2Listener: () => {},
    };
  }

  componentDidMount() {
    const { firebase } = this.props;
    const { drive1, drive2 } = this.state;

    const drive1Query = firebase?.collectionMostRecentQuery('drive1');
    const drive2Query = firebase?.collectionMostRecentQuery('drive2');

    const snapShotHandler = (
      reference: firestore.Query<firestore.DocumentData>,
      drive: Dictionary<DriveDocumentData>,
      setDrive: (drive: Dictionary<DriveDocumentData>) => void
    ) => {
      return reference.onSnapshot(
        (queryData) => {
          let driveClone = _.clone(drive); //this is very innefficient

          if (_.isEmpty(driveClone)) {
            driveClone = _.keyBy(
              queryData.docs.map((queryDocData) => {
                const dataArray = queryDocData.data().data as Array<number>;
                const driveData = new DriveData();
                driveData.fromNumbers(dataArray);
                return {
                  id: queryDocData.id,
                  data: driveData,
                };
              }),
              'id'
            );
            setDrive(driveClone);
          } else {
            queryData.docChanges().forEach((change) => {
              const dataArray = change.doc.data().data as Array<number>;
              switch (change.type) {
                case 'added':
                  driveClone[change.doc.id] = {
                    id: change.doc.id,
                    data: new DriveData().fromNumbers(dataArray),
                  };
                  break;
                case 'modified':
                  _.merge(driveClone[change.doc.id], {
                    id: change.doc.id,
                    data: new DriveData().fromNumbers(dataArray),
                  });
                  break;
                case 'removed':
                  _.unset(driveClone, change.doc.id);
                  break;
                default:
                  break;
              }
            });
            setDrive(driveClone);
          }
        },
        (err) => {
          console.log(err);
          debugger;
        }
      );
    };

    if (!_.isUndefined(drive1Query)) {
      const drive1Listener = snapShotHandler(
        drive1Query,
        drive1,
        (drive: Dictionary<DriveDocumentData>) =>
          this.setState({ drive1: drive })
      );
      this.setState({ drive1Listener });
    }
    if (!_.isUndefined(drive2Query)) {
      const drive2Listener = snapShotHandler(
        drive2Query,
        drive2,
        (drive: Dictionary<DriveDocumentData>) =>
          this.setState({ drive2: drive })
      );
      this.setState({ drive2Listener });
    }
  }

  componentWillUnmount() {
    if (_.isFunction(this.state.drive1Listener)) this.state.drive1Listener();
    if (_.isFunction(this.state.drive2Listener)) this.state.drive2Listener();
  }

  render() {
    const { drive1, drive2 } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <h2>Drive Status</h2>
          </Col>
        </Row>
        <hr />
        <Row>
          {[
            { label: 'Drive 1', drive: drive1 },
            { label: 'Drive 2', drive: drive2 },
          ].map((obj) => {
            const { label, drive } = obj;
            return (
              <Col key={label} md={6} xs={12}>
                <h3>{label}</h3>
                {_.map(drive, (d, k) => (
                  <Card key={k}>
                    <ListGroup flush>
                      {_.map(
                        _.filter(
                          _.map(d.data, (value, prop) => ({ prop, value })),
                          (x) => 'valueString' in x.value
                        ),
                        (val: { value: IDriveParameter; prop: string }) => {
                          const { value, prop } = val;
                          console.log(value, prop);
                          return (
                            <ListGroupItem
                              key={prop}
                              className={
                                _.isUndefined(contextualClassNames[prop])
                                  ? 'py-1'
                                  : contextualClassNames[prop](
                                      value.value,
                                      'py-1'
                                    )
                              }
                            >
                              <Row>
                                <Col md={6} xs={12}>
                                  <b>{value.name}</b>
                                </Col>
                                <Col md={6} xs={12}>
                                  {value.valueString}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          );
                        }
                      )}
                    </ListGroup>
                  </Card>
                ))}
              </Col>
            );
          })}
        </Row>
        <Row></Row>
      </Container>
    );
  }
}

//can't remove listener this way since useEffect won't have access to state if not passed as dependency ... which won't work as componentWillUnmount
// const Home2 = withFirebase(
//   (props: FirebaseProps & React.PropsWithChildren<object>) => {
//     const { firebase } = props;

//     const [drive1, setDrive1] = useState<Dictionary<DriveDocumentData>>({});
//     const [drive2, setDrive2] = useState<Dictionary<DriveDocumentData>>({});
//     const [drive1Listener, setDrive1Listener] = useState<() => void>(() => {});
//     const [drive2Listener, setDrive2Listener] = useState<() => void>(() => {});

//     useEffect(() => {
//       const drive1Query = firebase?.collectionQuery('drive1');
//       const drive2Query = firebase?.collectionQuery('drive2');

//       const snapShotHandler = (
//         reference: firestore.Query<firestore.DocumentData>,
//         drive: Dictionary<DriveDocumentData>,
//         setDrive: React.Dispatch<
//           React.SetStateAction<Dictionary<DriveDocumentData>>
//         >
//       ) => {
//         debugger;
//         return reference.onSnapshot(
//           (queryData) => {
//             debugger;
//             let driveClone = _.clone(drive); //this is very innefficient

//             if (_.isEmpty(driveClone)) {
//               driveClone = _.keyBy(
//                 queryData.docs.map((queryDocData) => {
//                   return { id: queryDocData.id, data: queryDocData.data() };
//                 }),
//                 'id'
//               );
//               debugger;
//               setDrive(driveClone);
//             } else {
//               queryData.docChanges().forEach((change) => {
//                 switch (change.type) {
//                   case 'added':
//                     driveClone[change.doc.id] = {
//                       id: change.doc.id,
//                       data: change.doc.data(),
//                     };
//                     break;
//                   case 'modified':
//                     _.merge(driveClone[change.doc.id], {
//                       id: change.doc.id,
//                       data: change.doc.data(),
//                     });
//                     break;
//                   case 'removed':
//                     _.unset(driveClone, change.doc.id);
//                     break;
//                   default:
//                     break;
//                 }
//               });
//               setDrive(driveClone);
//             }
//           },
//           (err) => {
//             console.log(err);
//             debugger;
//           }
//         );
//       };

//       if (!_.isUndefined(drive1Query))
//         setDrive1Listener(snapShotHandler(drive1Query, drive1, setDrive1));
//       if (!_.isUndefined(drive2Query))
//         setDrive2Listener(snapShotHandler(drive2Query, drive2, setDrive2));

//       return () => {};
//     }, [firebase, drive1, drive2, drive1Listener, drive2Listener]);

//     useEffect(() => {
//       return () => {
//         debugger;
//         if (_.isFunction(drive1Listener)) drive1Listener();
//         if (_.isFunction(drive2Listener)) drive2Listener();
//       };
//     }, []);

//     return (
//       <Container>
//         <Row>
//           <Col>
//             <h2>Home</h2>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <h3>Drive 1 {_.keys(drive1).length}</h3>
//           </Col>
//           {_.map(drive1, (d, k) => (
//             <Col key={k}>{JSON.stringify(d)}</Col>
//           ))}
//         </Row>
//         <Row>
//           <Col>
//             <h3>Drive 2 {_.keys(drive2).length}</h3>
//           </Col>
//           {_.map(drive2, (d, k) => (
//             <Col key={k}>{JSON.stringify(d)}</Col>
//           ))}
//         </Row>
//         <Row></Row>
//       </Container>
//     );
//   }
// );

const DriveList = withFirebase(DriveListComponent);
export { DriveList };
