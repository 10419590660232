export const LANDING = '/';
export const LOGIN = '/login';
export const HOME = '/home';
export const DRIVESTATUS = '/drivestatus';
export const DRIVE1 = '/drive1';
export const DRIVE2 = '/drive2';
export const CONTACT = '/contact';
export const ABOUT = '/about';
export const AUTOMATEDSEALING = '/automatedsealing';
export const REPORT = '/report';
export const REPORTADMIN = '/report-admin';

export const ROUTES = {
  LANDING,
  LOGIN,
  HOME,
  DRIVESTATUS,
  DRIVE1,
  DRIVE2,
  CONTACT,
  ABOUT,
  AUTOMATEDSEALING,
  REPORT,
  REPORTADMIN,
};
