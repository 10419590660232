import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthUserContext } from '.';
import { withFirebase } from '../config';

const PrivateRoute = withFirebase((props: any) => {
  const { component: Component, redirectPath, ...rest } = props;
  return (
    <AuthUserContext.Consumer>
      {({ authUser }) => {
        return (
          <Route
            {...rest}
            render={(props) =>
              authUser && authUser != null ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: redirectPath || '/login',
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        );
      }}
    </AuthUserContext.Consumer>
  );
});

export { PrivateRoute };
